var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrolling-wrapper-flexbox"},[_c('div',{staticClass:"searchbar",class:{ searchbar_active: _vm.searchActive, searchbar_margin_top: _vm.searchActive && _vm.showOdds }},[_c('div',{staticClass:"searchbar-item"},[_c('input',{ref:"searchInput",staticClass:"search-input",attrs:{"type":"text","placeholder":"User suchen..."},on:{"input":_vm.searchInputChanged}})])]),_c('table',{staticClass:"table card",attrs:{"id":"bg_rankings_detail_table"}},[_c('RankingsDetailTableHeader',{attrs:{"searchString":_vm.searchString,"matches":_vm.matches},on:{"sortingChanged":_vm.sortingChanged,"refreshRankingByMatchday":_vm.refreshRankingByMatchday}}),(_vm.matchdayRankings)?_c('tbody',{key:_vm.sorting + '_' + _vm.matchday},[_vm._l((_vm.matchdayRankings),function(ranking,index){return _c('tr',{key:index + '_' + _vm.sorting + '_' + _vm.matchday + '_' + ranking.fk_user,class:{ current_user: ranking.fk_user === _vm.currentUser }},[(_vm.sorting === 1 && !_vm.equalPoints(index))?_c('td',[_vm._v(_vm._s(ranking.position)+".")]):(_vm.sorting === 1 && _vm.equalPoints(index))?_c('td'):(_vm.sorting === 2 && !_vm.equalPoints(index))?_c('td',[_vm._v(_vm._s(index + 1)+".")]):(_vm.sorting === 2 && _vm.equalPoints(index))?_c('td'):_vm._e(),_c('td',{staticClass:"col_user"},[_vm._v(_vm._s(ranking.user_name))]),_c('td',{staticClass:"col_value",class:{ sort_column: _vm.sorting === 1 }},[_vm._v(_vm._s(_vm.totalpoints(ranking.fk_user)))]),_c('td',{staticClass:"col_value",class:{ sort_column: _vm.sorting === 2 }},[_vm._v(_vm._s(_vm.livepoints(ranking.fk_user)))]),_vm._l((_vm.matches),function(match,index){return _c('td',{key:index + '_' + _vm.sorting + '_' + _vm.matchday + '_' + match.id + '_' + ranking.fk_user,staticClass:"col_result",class:ranking.fk_user === _vm.currentUser ? 'current_user' : index % 2 == 0 ? 'col_white' : 'col_grey'},[_c('span',{class:{ bet_exact: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetResult,
                    bet_goal_diff: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetGoaldiff,
                    bet_result: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetTendency,
                    bet_no_points: _vm.betPoints(match, ranking.fk_user) === 0}},[_vm._v(" "+_vm._s(_vm.bet(match, ranking.fk_user))+" "),(_vm.betPoints(match, ranking.fk_user) > -1)?_c('small',[_vm._v(_vm._s(_vm.betPoints(match, ranking.fk_user)))]):_vm._e()])])})],2)}),_vm._l((_vm.usermatchdayRanking),function(ranking,index){return (!_vm.isUserTopTen)?_c('tr',{key:ranking.fk_user,class:{ current_user: ranking.fk_user === _vm.currentUser }},[(_vm.sorting === 1)?_c('td',[_vm._v(_vm._s(_vm.userLivePosition)+".")]):_c('td'),_c('td',{staticClass:"col_user"},[_vm._v(_vm._s(ranking.user_name))]),_c('td',{staticClass:"col_value"},[_vm._v(_vm._s(_vm.userTotalPoints))]),_c('td',{staticClass:"col_result"},[_vm._v(_vm._s(ranking.points_total))]),_vm._l((_vm.matches),function(match,index){return _c('td',{key:match.id + '_' + ranking.fk_user,staticClass:"col_result",class:ranking.fk_user === _vm.currentUser ? 'current_user' : index % 2 == 0 ? 'col_white' : 'col_grey'},[_c('span',{class:{ bet_exact: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetResult,
                    bet_goal_diff: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetGoaldiff,
                    bet_result: _vm.betPoints(match, ranking.fk_user) === _vm.pointsBetTendency,
                    bet_no_points: _vm.betPoints(match, ranking.fk_user) === 0}},[_vm._v(" "+_vm._s(_vm.bet(match, ranking.fk_user))+" "),(_vm.betPoints(match, ranking.fk_user) > -1)?_c('small',[_vm._v(_vm._s(_vm.betPoints(match, ranking.fk_user)))]):_vm._e()])])})],2):_vm._e()})],2):_vm._e(),_c('tfoot')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }