/* eslint-disable */
<template>

    <div :key="componentKey" class="betMatchList" style="padding-top: 16px;">

      <a v-if="betsEditable && showOdds" :href="this.deepLink" target="_blank" style="text-decoration: none;">
        <div v-bind:class="betsEditable && showOdds ? 'betpromo--visible' : ''" class="betpromo betpromo--small">
          <div class="betpromo__logo">
            <img src="../assets/icons/ic_bwin.svg" alt="bwin Logo">
          </div>
          <div class="betpromo__text">
            <div class="betpromo__label">
              Gewinne {{ totalOdds | formatNumber }}€
            </div>
            <div class="betpromo__sublabel">
              Lizenziert (Whitelist) | 18+ | Suchtrisiko | <object><a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a></object>
            </div>
          </div>
          <div class="betpromo__cta">
            <img src="../assets/icons/ic_12_next.svg" alt="" />
          </div>
        </div>
      </a>
      
        <EditBetRow
                @guestInputFocusLost="guestInputFocusLost"
                :matchIndex="index * 2"
                :match="match"
                :previousMatch="matches[index - 1 > 0 ? index - 1 : 0]"
                :nextMatch="matches[index + 1 < matches.length ? index + 1 : matches.length - 1]"
                v-for="(match, index) in matches"
                :key="match.id"
                :ref="match.id"
        ></EditBetRow>
    
        <small v-if="lastBetSaveSuccess && matches && matches.length > 0" style="margin-top: -5px">
            Zuletzt gespeichert: {{ lastBetSaveSuccess | formatDateDayMonth }} um {{ lastBetSaveSuccess | formatTime }} Uhr
        </small>

        <div v-if="betsEditable && showOdds" class="betpromo betpromo--large">
          <div class="betpromo__logo">
            <a :href="deepLink" target="_blank">
              <img src="../assets/icons/ic_bwin.svg" alt="bwin Logo">
            </a>
          </div>
          <div class="betpromo__text">
            <div class="betpromo__label">
              Gewinne {{ totalOdds | formatNumber }}€
            </div>
            <div class="betpromo__sublabel">
              ... {{ $t('with_only_10_euro_stake') }}
            </div>
          </div>
          <a :href="deepLink" target="_blank" class="button buttom--small">
            Jetzt Wette abschließen
          </a>
        </div>
        
        <div v-if="betsEditable && showOdds" class="bet-box-infotext">
          Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a>
        </div>
      
        
    </div>
</template>

<script>
    import EditBetRow from '../components/EditBetRow'
    import NavigationBar from './../components/NavigationBar'
    import { isNumeric } from '../vuex/helpers'
    import { DEFAULT_BET } from '../main'
    export default {
        name: 'BetMatchList',
        props: {
          matchday: {
            type: Number,
            default: () => {
              return 1
            }
          }
        },
        data () {
          return {
            componentKey: 0
          }
        },
        components: {
          EditBetRow, NavigationBar
        },
        created () {
            if (this.$store.state.teams.length === 0 && this.$store.state.leagueId > 0) {
              this.$store.dispatch('fetchTeams', this.$store.state.leagueId)
            }
            if (this.$store.state.matches.length === 0 && this.$store.state.leagueId > 0) {
                this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
            }
            if(this.$store.state.bets.length === 0 && this.$store.state.user) {
                this.$store.dispatch('fetchBets', this.$store.state.user.id)
            }
            if(!this.countryBlocked() && this.$store.state.betgame && this.$store.state.betgame.hide_ads === false && this.$store.state.user &&
              this.$store.state.user.isadult === true && this.$store.state.spAds === 'bet' && this.$store.state.matchBets.length === 0 &&
              this.$store.state.betgame.competition) {
              this.$store.dispatch('fetchMatchBets', this.$store.state.betgame.competition.id)
            }
            //this.matchday = this.$store.state.currentMatchday
        },
        mounted() {
          this.componentKey += 1
        },
        computed: {
          deepLink() {
          // https://sports.bwin.de/de/sports
          // https://sports.bwin.de/de/sports/addbets?ResultIDs=1128478629&GameIDs=378033583
  
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
            }
            var options = ''
            for (var i = 0; i < tempMatches.length; i++) {
              const match = tempMatches[i]
              if(isNumeric(match.goalshome) || isNumeric(match.goalsguest) || isNumeric(match.livegoalshome) || isNumeric(match.livegoalsguest)) {
                continue
              }
              const md = this.parseMatchdate(match.matchdate)
              if(md < Date.now()) {
                continue
              }
              const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
              if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
                continue
              }
              const bwinbets = this.$store.getters.getMatchBet(match.id)
              if(bwinbets.length > 0) {
                const bwinbet =  bwinbets[0]
                if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
                  options += bwinbet.bet_id + '-' + bwinbet.result_market_id + '-'
                  if (bet.goalshome > bet.goalsguest) {
                    options += bwinbet.bet_1_id + ','
                  } else if (bet.goalshome < bet.goalsguest) {
                    options += bwinbet.bet_2_id + ','
                  } else if (bet.goalshome === bet.goalsguest) {
                    options += bwinbet.bet_x_id + ','
                  }
                }
              }
            }
            if(options.length > 0) {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?stake=10&wm=5047188&options=' + options.substring(0, options.length - 1)
            } else {
              return 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188'
            }
          },
          totalOdds() {
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return 0
            }
            var q = DEFAULT_BET
            for (var i = 0; i < tempMatches.length; i++) {
              const match = tempMatches[i]
              if(isNumeric(match.goalshome) || isNumeric(match.goalsguest) || isNumeric(match.livegoalshome) || isNumeric(match.livegoalsguest)) {
                continue
              }
              const md = this.parseMatchdate(match.matchdate)
              if(md < Date.now()) {
                continue
              }
              const bet = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, match.id)
              if(bet === null || bet === undefined || !isNumeric(bet.goalshome) || !isNumeric(bet.goalsguest)) {
                continue
              }
              const bwinbets = this.$store.getters.getMatchBet(match.id)
              if(bwinbets.length > 0) {
                const bwinbet =  bwinbets[0]
                if(isNumeric(bwinbet.bet_1) && isNumeric(bwinbet.bet_2) && isNumeric(bwinbet.bet_x)) {
                  if (bet.goalshome > bet.goalsguest) {
                    q *= bwinbet.bet_1
                  } else if (bet.goalshome < bet.goalsguest) {
                    q *= bwinbet.bet_2
                  } else if (bet.goalshome === bet.goalsguest) {
                    q *= bwinbet.bet_x
                  }
                }
              }
            }
            if(isNaN(q)) {
              return DEFAULT_BET
            }
            return Math.round(q * 100) / 100
          },
          lastBetSaveSuccess() {
            return this.$store.state.lastBetSaveSuccess
          },
            matches () {
                const m = this.$store.getters
                        .getMatchesByMatchday(this.matchday)
                        .sort(function (a, b) {
                            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
                        })
                return m
            },
            matchdayCount () {
                return this.$store.state.matchdayCount
            },
          showOdds() {
            return false 
            /*
            if(this.$store.state.betgame && this.$store.state.user) {
              if(!this.countryBlocked() && this.$store.state.betgame.hide_ads === false && 
                  this.$store.state.user.isadult === true && this.$store.state.spAds === 'bet') {
                const tempMatches = this.matches
                if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
                  return false
                }
                for (var i = 0; i < tempMatches.length; i++) {
                  const match = tempMatches[i]
                  if((this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) ||
                      (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest))) {
                    continue
                  }
                  const r = this.$store.getters.getMatchBet(match.id)
                  const available = r !== null && r !== undefined && r.length > 0
                  if(!available) {
                    return false
                  }
                }
                return true
              }
            }
            return false
            */
          },
          betsEditable () {
            const tempMatches = this.matches
            if(tempMatches === null || tempMatches === undefined || tempMatches.length === 0) {
              return false
            }
            for (var i = 0; i < tempMatches.length; i++) {
              var match = tempMatches[i]
              const md = this.parseMatchdate(match.matchdate)
              if (md > Date.now() && !this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
                !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
                return true
              }
            }
            return false
          },
        },
        methods: {
          deepLinkClicked() {
            if(this.deepLink) {
              this.$gtag.event('Kombiwette', {
                'event_category': 'bwin',
                'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
                'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
                'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
                'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
              })
              if(window.parent) {
                window.parent.open(this.deepLink, '_blank')
              } else {
                window.open(this.deepLink, '_blank')
              }
            }
          },
            betboxClicked: function(event) {
                if(this.getMatchBet) {
                  window.open('https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188', '_blank')
                }
            },
            openMatchdaySelect() {
                this.$emit('opemMatchdaySelect', {})
            },
            openMenu() {
              this.$modal.show('betgame-menu')
            },
            guestInputFocusLost (matchIndex) {
                const i = matchIndex / 2 + 1
                if (i < this.matches.length) {
                    const nextMatch = this.matches[i]
                    const nextRow = this.$refs[nextMatch.id]
                    if (nextRow.length > 0) {
                        const nextInput = nextRow[0].$refs['bet_input_home']
                        nextInput.focus()
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button {
        background: $colorPrimary;
        font-size: 17px;
        font-weight: 500;
        border: 0;
        border-radius: 6px;
        padding: 14px 19px;
        a {
            color: white;
            text-decoration: none;
        }
    }
    small {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: $colorGreyMid;
        text-align: center;
        padding: 4px 0;
    }
    
    ul {
        list-style: none;
    }
    
    .betMatchList {
        display: block;
    }
    
    .col_team {
        width: 44%;
    }
    
    .col_goal {
        width: 5%;
    }
    
    .col_center {
        width: 2%;
    }

    .bet-kombibox {
        background: $colorWhite;
        box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
        border-radius: 11px;
        text-align: center;
        padding: 16px 16px 8px 16px;
        position: relative;
        max-width: 100%;

        &:before {
            content: '-W-';
            position: absolute;
            right: 10px;
            top: 8px;
            text-align: right;
            font-size: 9px;
            color: rgba($colorGreyLight, .18);
        }

        &-label {
            font-weight: 500;
            color: $colorGreyDark;
            font-size: 17px;
            margin-bottom: -2px;
        }

        &-sublabel {
            color: $colorGreyMid;
            font-size: 14px;
            margin-bottom: 10px;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-top: -4px;
                margin-left: 3px;
            }
        }
    }

    .bet-presented {
        color: $colorGreyMid;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    
        img {
            margin-top: -4px;
            margin-left: 3px;
        }
    }

    .bet-box {
        display: flex;
        flex-wrap: wrap;
        padding: 0 8px;
        margin: 0px auto 26px;
        justify-content: space-between;
        max-width: 592px;
        position: relative;
        &-infotext {
            font-size: 14px;
            color: $colorGreyMid;
            text-align: center;
            width: 100%;
            margin-top: 7px;
        }
    }

    .betpromo {
      display: flex;
      align-items: center;
      position: relative;
      background: $colorWhite;
      box-shadow: 0 8px 24px 0 rgba(49,49,55,0.08);
      border-radius: 4px;
      padding: 8px;

      &:before {
        content: '-W-';
        position: absolute;
        right: 4px;
        top: 2px;
        text-align: right;
        font-size: 7px;
        color: rgba($colorGreyLight, .18);
      }

      &--sticky {
        position: sticky;
        bottom: 52px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 2px 8px 0 rgba(0,0,0,0.08), 0 12px 24px 0 rgba(49,49,55,0.08);
        cursor: pointer;
        margin: 0 -8px -52px;
        opacity: 0;
        transform: translateY(52px);
        transition: all .4s ease-in-out;

        &.betpromo--visible {
          margin-bottom: 0;
          transform: translateY(0);
          opacity: 1;
        }
      }

      &--small {
        margin-bottom: 24px;
        cursor: pointer;
        margin-top: calc(-52px + -24px);
        opacity: 0;
        transition: all .4s ease-in-out;

        &.betpromo--visible {
          margin-top: 0;
          opacity: 1;
        }
      }

      &--large {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 16px 16px;
        margin-top: 40px;

        &:before {
          top: 10px;
          right: 10px;
        }
      }

      &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 36px;
        background: #000000;
        border-radius: 3px;

        .betpromo--large & {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin: -24px 0 16px;
        }
      }

      &__text { 
        display: flex;
        width: calc(100% - 80px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__cta {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: #FFCB04;
        margin: 0 6px 0 8px;
      }

      &__label {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        text-align: center;
        
        .betpromo--large & {
          font-size: 17px;
          margin-bottom: 4px;
        }
      }

      &__sublabel {
        font-size: 9px;
        font-weight: 400;
        color: rgba($colorGreyDark, .48);
        margin-top: 3px;
        padding-left: 10px;
        padding-right: 5px;
        text-align: center;
        
        .betpromo--large & {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .button {
        font-size: 14px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        background: #FFCB04;
        color: #000000;
        text-decoration: none;
      }
    }
</style>
