import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store, { menumutations } from './vuex/store'
import SmartTable from 'vuejs-smart-table'
import VModal from 'vue-js-modal'
import InfiniteLoading from 'vue-infinite-loading'
import VueCookies from 'vue-cookies'
import i18n from './i18n'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import moment from 'moment'
import Vue2TouchEvents from 'vue2-touch-events'
import FlagIcon from 'vue-flag-icon'
import SocialSharing from 'vue-social-sharing'
import Multiselect from 'vue-multiselect'
import TextareaAutosize from 'vue-textarea-autosize'
import VueClipboard from 'vue-clipboard2'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueLogger from 'vuejs-logger';
import VueToast from 'vue-toast-notification';
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGtag from "vue-gtag";
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import visibility from 'vue-visibility-change'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Numeral from 'numeral'

Vue.use(VueGtag, {
  config: { id: "G-0L3VGS20WF" },
  bootstrap: false
}, router);

const options = {
  isEnabled: true,
  logLevel : process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : false,
  showMethodName : false,
  separator: '',
  showConsoleColors: false
};

Vue.use(Numeral)
Vue.use(VueAwesomeSwiper, { autoHeight: true } )
Vue.use(visibility)
Vue.use(Loading)
Vue.use(VueLogger, options);
Vue.use(VueToast);
Vue.use(vueSmoothScroll)
Vue.use(VueClipboard)
Vue.use(TextareaAutosize)
Vue.component('multiselect', Multiselect)
Vue.use(SocialSharing);
Vue.use(FlagIcon);
Vue.use(Vue2TouchEvents)
Vue.component('v-select', vSelect)
Vue.use(VueFormWizard)
Vue.use(VueCookies)
Vue.use(InfiniteLoading, { /* options */ });
Vue.use(VModal)
Vue.use(SmartTable)

Numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€'
  }
})

Vue.filter("formatNumber", function (value) {
  if(isNaN(value)) {
    return ''
  }
  var v = value
  if(v > 1000000000000000) {
    v = v/1000000000000000
    return Numeral(v).format("0,00")  + ' Brd. '
  } else if(v > 1000000000) {
    v = v/1000000000
    return Numeral(v).format("0,00") + ' Bio. '
  } else if(v > 1000000000) {
    v = v/1000000000
    return Numeral(v).format("0,00")  + ' Mrd. '
  } else if(v > 1000000) {
    v = v/1000000
    return Numeral(v).format("0,00") + ' Mio. '
  }
  return Numeral(value).format("0,00.00")
});
Vue.filter('formatDateShort', function(value) {
  if (value) {
    if(moment.locale() === 'de') {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.YYYY LT') + ' Uhr'
    } else {
      return moment(String(value)).format('L LT')
    }
  }
});
Vue.filter('formatDate', function(value) {
  if (value) {
    if(moment.locale() === 'de') {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('dddd, DD.MM.YYYY')
    } else {
      return moment(String(value)).format('dddd, L')
    }
  }
});
Vue.filter('formatTime', function(value) {
  if (value) {
    if(moment.locale() === 'de') {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('LT') + ' Uhr'
    } else {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('LT')
    }
  }
});
Vue.filter('formatDateSlider', function(value) {
  if (value) {
    if(moment.locale() === 'de') {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.YY')
    } else {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('MM/DD/YY')
    }
  }
});
Vue.filter('formatDateDayMonth', function(value) {
  if (value) {
    if(moment.locale() === 'de') {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('DD.MM.')
    } else {
      return moment(String(value), ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss'], true).format('MM/DD')
    }
  }
});


Array.prototype.removeDuplicates = function () {
  return this.filter(function (item, index, self) {
    return self.indexOf(item) === index;
  });
};

Vue.mixin({
  methods: {
    isPlaceholderTeam: function (teamid) {
      if(teamid >= 817 && teamid <= 848) {
        return true
      } else {
        return false
      }
    },
    isBattle() {
      return this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_BATTLE_ID
    },
    isTournament() {
      const tournments = [19,33,107,139,533,541,999,1110,1180]
      if(this.$store.state.betgame && this.$store.state.betgame.competition) {
        return tournments.includes(parseInt(this.$store.state.betgame.competition.id))
      } else {
        if(this.$store.state.leagueId && parseInt(this.$store.state.leagueId) > 0) {
          return tournments.includes(parseInt(this.$store.state.leagueId))
        } else {
          return false
        }
      }
    },
    getTeamFullName(teamId, teamName) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null) {
        if(this.$i18n.locale === 'de' && team.name !== null && team.name.length > 0) {
          return team.name
        } else {
          return team.name_int
        }
      }
      return teamName
    },
    countryBlocked() {
      if(this.$store.state.geoData === null) {
        this.$store.dispatch('getGeoData', []).then(response => {
        }, error => {
        })
        return true
      }
      if(this.$store.state.geoData !== null && this.$store.state.geoData.Iso !== null && this.$store.state.geoData.Result === 'ok') {
        const isocode = this.$store.state.geoData.Iso
        if(isocode.toUpperCase() === 'DE' || isocode.toUpperCase() === 'AT') {
          return false
        }
      }
      return true
    },
    hasSeasonStarted () {
      return this.$store.getters.startedMatches > 0
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    isNullOrUndefined(data) {
      return data === null || data === undefined
    },
    isValidEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    hostReachable () {
      // Handle IE and more capable browsers
      var xhr = new (window.ActiveXObject || XMLHttpRequest)('Microsoft.XMLHTTP')
      // Open new request as a HEAD to the root hostname with a random param to bust the cache
      xhr.open('HEAD', '//' + window.location.hostname + '/?rand=' + Math.floor((1 + Math.random()) * 0x10000), false)
      // Issue request and handle response
      try {
        xhr.send()
        return (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304))
      } catch (error) {
        return false
      }
    },
    hexToRgbA(hex, a){
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + a + ')';
      }
      throw new Error('Bad Hex');
    },
    clearBGValue(key) {
      window.localStorage.removeItem(key)
    },
    setBGValue(key, value) {
      window.localStorage.setItem(key, value)
    },
    getBGValue(key) {
      return window.localStorage.getItem(key)
    },
    parseMatchdate(matchdate) {
      return moment(matchdate, MATCH_DATE_FORMAT, true)
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    getTeamName(teamId, teamName) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.shortname !== null && team.shortname.length > 0) {
        return team.shortname
      }
      return teamName
    },
    getTeamColor(teamId) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.color !== null && team.color.length > 0) {
        return team.color
      }
      return '#778833'
    },
    getTeamLogo(teamId) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.logo !== null && team.logo.length > 0) {
        return team.logo
      }
      return null
    },
    getHeimspielLogo(teamId, size) {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.heimspielid !== null && team.heimspielid.length > 0) {
        return 'https://s.hs-data.com/gfx/emblem/common/' + size + 'x' + size + '/' + team.heimspielid + '.png'
      }
      return null
    },
    isHeimspielLeague(league) {
      const heimspielleagues = ['91', '97', '107', '111', '116', '139', '1110']
      return heimspielleagues.includes('' + league)
    },
    getPoints(bet, match) {
      if(bet === null || match === null) {
        return 0
      }
      if(!this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
        !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest)) {
        return 0
      }
      var goalshome = null
      var goalsguest = null
      if(match.livegoalshome !== null && match.livegoalsguest !== null &&
        match.goalshome === null && match.goalsguest === null) {
        goalshome = match.livegoalshome
        goalsguest = match.livegoalsguest
      } else if(match.goalshome !== null && match.goalsguest !== null) {
        goalshome = match.goalshome
        goalsguest = match.goalsguest
      }
      if(goalshome !== null && goalsguest !== null) {
        if ((bet.goalshome === goalshome) && (bet.goalsguest === goalsguest)) {
          return this.$store.getters.pointsBetResult
        } else if ((bet.goalshome - bet.goalsguest) === (goalshome - goalsguest)) {
          return this.$store.getters.pointsBetGoaldiff
        } else if ((bet.goalshome > bet.goalsguest) && (goalshome > goalsguest)) {
          return this.$store.getters.pointsBetTendency
        } else if ((bet.goalshome < bet.goalsguest) && (goalshome < goalsguest)) {
          return this.$store.getters.pointsBetTendency
        }
      }
      return 0
    },
  }
})

Vue.config.productionTip = false
// Vue.config.silent = true

Vue.config.errorHandler = err => {
  Vue.$log.debug('ERROR: ' + router.currentRoute.path + ' --- ' + err.stack)
}

export const TOP_TEN = 30
export const MATCH_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const DASHBOARD_RANKINGS_SIZE = 5
export const PADDING_BOTTOM_DEFAULT = 0
export const PADDING_BOTTOM_DSP = 200
export const PADDING_BOTTOM_FUNKE = 0
export const DEFAULT_BET = 10.0
export const API_TIMEOUT = 10000
export const TOAST_DURATION = 3000

// https://vuejs.org/v2/api/#vm-mount
// https://vuejs.org/v2/api/#render
new Vue({
  name: 'app',
  el: '#betgame-container',
  router,
  store,
  i18n,
  render(h) {
    return h(App, {
      props: {
        //betgameId: this.$el.attributes.betgame.value,
        //betgameName: this.$el.attributes.bgname.value,
        //betgameHost: this.$el.attributes.bghost.value
      }
    })
  }
})
