/* eslint-disable */
<template>
  <div id="nav">
    <!--
    <router-link to="/">
      Home
    </router-link>
    -->
    <router-link v-if="loggedIn" to="/dashboard">
      Dashboard
    </router-link>
    <router-link v-if="loggedIn" to="/bet">
      Tippen
    </router-link>
    <router-link v-if="loggedIn" to="/table">
      Tabelle
    </router-link>
    <router-link v-if="loggedIn" to="/schedule">
      Spielplan
    </router-link>
    <router-link v-if="loggedIn" to="/rankings">
      Wertung
    </router-link>
    <router-link v-if="loggedIn" to="/rankingsbymatchday">
      Spieltagswertung
    </router-link>
    <router-link v-if="loggedIn" to="/profile">
      Profil
    </router-link>
    <router-link v-if="loggedIn" to="/rules">
      Spielregeln
    </router-link>
    <router-link v-if="!loggedIn" to="/login" class="button">
      Login
    </router-link>
    <button v-else type="button" class="logoutButton" @click="logout">
      Logout
    </button>
  </div>
</template>

<script>
import { authComputed } from '../vuex/helpers.js'
export default {
  computed: {
    ...authComputed
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  align-items: start;
  min-height: 50px;
  padding: 0.2em 1em;
  background: linear-gradient(to right, #16c0b0, #84cf6a);
}

.nav-welcome {
  margin-left: auto;
  margin-right: 1rem;
  color: white;
}

a {
  font-weight: bold;
  color: #2c3e50;
  margin: auto 0.8em auto 0.4em;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.router-link-exact-active {
  color: white;
  border-bottom: 2px solid #fff;
}

button,
.button {
  margin-left: auto;
  background: white;
  text-decoration: none;
  color: #2c3e50;

  &.router-link-active {
    color: #2c3e50;
  }
}

.logoutButton {
  cursor: pointer;
}

.nav-welcome + button {
  margin-left: 0;
}
</style>
