/* eslint-disable */
<template>
  <div class="tournament" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <NavigationBar >
      <template v-slot:center>
        {{ $t('tournament.groups_and_schedule') }}
      </template>
      <template v-slot:right>
      </template>
    </NavigationBar>

    <div id="tournament_nav">
      <div :id="tab.name" v-for="(tab, index) in tabsnew" :key="tab.name" class="tournament_tab" v-bind:class="{ tournament_tab_selected: activeTab === tab.name, last_tab: index === (tabs.length - 1) }" v-on:click="handleClick(tab.name)">{{ tab.name }}</div>
    </div>

    <swiper ref="mySwiper" :options="swiperOptions" @slideChangeTransitionEnd="slideChangeTransitionEnd">
      <swiper-slide v-for="(tab, index) in tabsnew" :key="'content_' + tab.name">
        <tournament-group v-if="tab.type === 'TournamentGroup'" class="tournament-slider-item" :dataObject="tab">
        </tournament-group>
        <tournament-round v-else-if="tab.type === 'TournamentRound'" class="tournament-slider-item" :dataObject="tab">
        </tournament-round>
      </swiper-slide>
    </swiper>
    
  </div>
</template>

<script>
  import NavigationBar from './../components/NavigationBar'
  import TournamentGroup from '../components/TournamentGroup'
  import TournamentRound from '../components/TournamentRound'
  
export default {
  name: 'tournament',
  components: { NavigationBar, TournamentGroup, TournamentRound },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
      },
      activeTab: 'Gruppe A',
      tabs: [
        {
          name: 'Gruppe A',
          type: 'TournamentGroup',
          matches: [
            {
              codeHome: 'se',
              codeGuest: 'gb-wls',
              teamHome: 'Schweden',
              teamGuest: 'Wales',
              goalsHome: '1',
              goalsGuest: '2',
              date: 'Montag, 02.07.2020 15:00 Uhr'
            },
            {
              codeHome: 'it',
              codeGuest: 'es',
              teamHome: 'Italien',
              teamGuest: 'Spanien',
              goalsHome: '0',
              goalsGuest: '2',
              date: 'Montag, 07.07.2020 22:30 Uhr'
            }
          ],
          table: [
            {
              position: '1',
              code: 'pl',
              name: 'Polen',
              mathes: '2',
              points: '4',
              goals: '6',
              goalsAgainst: '3',
            },
            {
              position: '2',
              code: 'se',
              name: 'Schweden',
              mathes: '2',
              points: '3',
              goals: '3',
              goalsAgainst: '3',
            },
            {
              position: '3',
              code: 'ch',
              name: 'Schweiz',
              mathes: '2',
              points: '2',
              goals: '3',
              goalsAgainst: '3',
            },
            {
              position: '4',
              code: 'gb-wls',
              name: 'Wales',
              mathes: '2',
              points: '1',
              goals: '1',
              goalsAgainst: '3',
            }
          ]
        },
        {
          name: 'Gruppe B',
          type: 'TournamentGroup'
        },
        {
          name: 'Gruppe C',
          type: 'TournamentGroup'
        },
        {
          name: 'Achtelfinale',
          type: 'TournamentRound',
          matches: [
            {
              codeHome: 'gb-eng',
              codeGuest: 'pt',
              teamHome: 'England',
              teamGuest: 'Portugal',
              goalsHome: '1',
              goalsGuest: '2',
              date: 'Montag, 22.07.2020 15:00 Uhr'
            },
            {
              codeHome: 'de',
              codeGuest: 'es',
              teamHome: 'Deutschland',
              teamGuest: 'Spanien',
              goalsHome: '0',
              goalsGuest: '2',
              date: 'Montag, 07.07.2020 22:30 Uhr'
            }
          ],
        },
        {
          name: 'Viertelfinale',
          type: 'TournamentRound'
        },
        {
          name: 'Halbfinale',
          type: 'TournamentRound'
        },
        {
          name: 'Finale',
          type: 'TournamentRound'
        }
      ]
    }
  },
  created() {
    //if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', [])
    //}
    if(this.$store.state.betgame.competition) {
      this.$store.dispatch('fetchTournamentRounds', this.$store.state.betgame.competition.id).then(() => {
      })
      this.$store.dispatch('fetchTournamentMatches', this.$store.state.betgame.competition.id).then(() => {
      })
    }
  },
  mounted() {
  },
  computed: {
    swiper() {
      return document.querySelector('.swiper-container').swiper
    },
    tabsnew() {
      var result = []
      const tr = this.$store.state.tournamentRounds
      for(var i=0; i<tr.length; i++) {
        const round = tr[i]
        if(round.type === 1) {
          const tg = this.$store.state.tournamentGroups.filter(s => s.fk_tournament_round === round.id).sort(function(a, b) {
            return a.sorting - b.sorting
          })
          for(var j=0; j<tg.length; j++) {
            const group = tg[j]
            const tournametmatches = this.$store.state.tournamentMatches.filter(s => s.fk_tournament_group === group.id)
            const roundMatches = this.getRoundMatches(tournametmatches)
            const tt = this.$store.state.tournamentGroupTables.filter(s => s.fk_tournament_group === group.id)
            const table = this.getGroupTable(tt)
            result.push({
              name: group.name,
              type: 'TournamentGroup',
              matches: roundMatches,
              table: table
            })
          }
        } else {
          const tournametmatches = this.$store.state.tournamentMatches.filter(s => s.fk_tournament_round === round.id)
          const roundMatches = this.getRoundMatches(tournametmatches)
          result.push({
            name: round.name,
            type: 'TournamentRound',
            matches: roundMatches
          })
        }
      }
      return result
    }
  },
  methods: {
    slideChangeTransitionEnd() {
      this.activeTab = this.tabsnew[this.swiper.activeIndex].name
      const tabbar = document.getElementById('tournament_nav')
      const tab = document.getElementById(this.activeTab)
      tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
    },
    getGroupTable(positions) {
      var tablesPositions = []
      for(var j=0; j<positions.length; j++) {
        const position = positions[j]
        const team = this.$store.state.teams.filter(s => s.id === position.fk_team)[0]
        if(team === undefined) {
          continue
        }
        tablesPositions.push({
          position: position.platz,
          code: team.logo,
          color: team.color,
          name: team.name,
          mathes: position.spiele,
          points: position.punkte,
          goals: position.torepositiv,
          goalsAgainst: position.torenegativ,
        })
      }
      tablesPositions.sort(function(a, b) {
        return a.position - b.position
      })
      return tablesPositions
    },
    getRoundMatches(matches) {
      var roundMatches = []
      for(var j=0; j<matches.length; j++) {
        const tournamentmatch = matches[j]
        const match = this.$store.state.matches.filter(s => s.id === tournamentmatch.fk_match)[0]
        if(match === undefined) {
          continue
        }
        const th = this.$store.state.teams.filter(s => s.id === match.fk_team_home)[0]
        const tg = this.$store.state.teams.filter(s => s.id === match.fk_team_guest)[0]
        if(th === undefined || tg === undefined) {
          continue
        }
        roundMatches.push({
          codeHome: th.logo,
          codeGuest: tg.logo,
          colorHome: th.color,
          colorGuest: tg.color,
          teamHome: th.shortname,
          teamGuest: tg.shortname,
          goalsHome: this.isNumeric(match.goalshome) ? match.goalshome : (this.isNumeric(match.livegoalshome) ? match.livegoalshome : ''),
          goalsGuest: this.isNumeric(match.goalsguest) ? match.goalsguest : (this.isNumeric(match.livegoalsguest) ? match.livegoalsguest : ''),
          date: match.matchdate
        })
      }
      roundMatches.sort(function (a, b) {
        return Date.parse(a.date) - Date.parse(b.date)
      })
      return roundMatches
    },
    getTabIndex(tabname) {
      for(var i=0; i<this.tabsnew.length; i++) {
        if(this.tabsnew[i].name === tabname) {
          return i
        }
      }
      return 0
    },
    handleClick(tabname) {
      this.activeTab = tabname
      const tabbar = document.getElementById('tournament_nav')
      const tab = document.getElementById(tabname)
      tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
      this.swiper.slideTo(this.getTabIndex(tabname))
    }
  }
}
</script>

<style lang="scss" scoped>

  .slide-leave-active,
  .slide-enter-active {
    transition: 300ms;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
  
  .tournament-slider {
    overflow: hidden;
    position: relative;
  }
  
  .tournament-slider component {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right:0;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  #tournament_nav::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  #tournament_nav {
    display: flex;
    justify-content: flex-start;
    -ms-overflow-style: none;
    margin: 12px -16px 24px;
    width: calc(100% + 32px);
    overflow-x: scroll;
    scroll-behavior: smooth; /* <--- */
    white-space: nowrap;
    box-shadow: inset 0 -2px 0 0 rgba($colorGreyDark, .08);

    @media screen and (min-width: 768px) {
      margin: 16px -16px 48px;
    }
  }
  
  .tournament_content {
    width: 100%;
  }

  .tournament-slider-item {
    max-width: 576px;
    margin: 0px auto;
  }
  
  .tournament_tab {
    font-size: 17px;
    color: $colorGreyMid;
    padding: 16px;
    cursor: pointer;

    &:first-child {
      margin-left: 16px;

      @media screen and (min-width: 768px) {
        margin-left: auto;
      }
    }

    &:last-child {
      margin-right: 16px;

      @media screen and (min-width: 768px) {
        margin-right: auto;
      }
    }
  }
  
  .tournament_tab_selected {
    color: $colorPrimary;
    box-shadow: inset 0 -2px 0 0 rgba($colorPrimary, 1);
  }

  /*.last_tab {
    padding-right: 100px;
  }*/
  
</style>
