import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import Bet from './views/Bet.vue'
import Table from './views/Table.vue'
import Tournament from './views/Tournament.vue'
import Rankings from './views/Rankings'
import Rules from './views/Rules.vue'
import ErrorPage from './views/ErrorPage.vue'
import store from './vuex/store.js'
import RankingsByMatchday from './views/RankingsByMatchday'
import BetgameTippen from './views/BetgameTippen'
import { TOAST_DURATION } from './main'
import StartPage from './views/StartPage'

Vue.use(Router)


const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    //document.getElementById("app").scrollIntoView()
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/register',
      name: 'register',
      component: RegisterUser,
      props: true
    },
    {
      path: '/logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('logout').then(() => {
          next('/')
        })
      }
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: ResetPassword
    },
    {
      path: '/enterpasswordreset',
      name: 'enterpasswordreset',
      component: EnterPasswordReset,
      props: true
    },
    {
      path: '/pr',
      name: 'pr',
      component: EnterPasswordReset,
      props: true
    },
    {
      path: '/validateregistration',
      name: 'validateregistration',
      component: ValidateRegistration,
      props: true
    },
    {
      path: '/vr',
      name: 'vr',
      component: ValidateRegistration,
      props: true
    },
    */
    {
      path: '/bet',
      name: 'bet',
      component: Bet,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/specialquestions',
      name: 'specialquestions',
      component: SpecialQuestions,
      meta: { requiresAuth: true }
    },
     */
    {
      path: '/table',
      name: 'table',
      component: Table,
      meta: { requiresAuth: true }
    },
    {
      path: '/tournament',
      name: 'tournament',
      component: Tournament,
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
      meta: { requiresAuth: false }
    },
    {
      path: '/rankings',
      name: 'rankings',
      component: Rankings,
      meta: { requiresAuth: true }
    },
    {
      path: '/rankingsbymatchday',
      name: 'rankingsbymatchday',
      component: RankingsByMatchday,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangenickname',
      name: 'profilechangenickname',
      component: ProfileChangeNickname,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangeemail',
      name: 'profilechangeemail',
      component: ProfileChangeEmail,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangepassword',
      name: 'profilechangepassword',
      component: ProfileChangePassword,
      meta: { requiresAuth: true }
    },
    {
      path: '/profilechangenotifications',
      name: 'profilechangenotifications',
      component: ProfileChangeNotifications,
      meta: { requiresAuth: true }
    },
    {
      path: '/profiledeleteaccount',
      name: 'profiledeleteaccount',
      component: ProfileDeleteAccount,
      meta: { requiresAuth: true }
    },
    {
      path: '/userbetgamesettings',
      name: 'userbetgamesettings',
      component: UserBetgameSettings,
      meta: { requiresAuth: true }
    },
     */
    {
      path: '/rules',
      name: 'rules',
      component: Rules,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/createuserbetgame',
      name: 'createuserbetgame',
      component: CreateUserBetgame
    },
    {
      path: '/betgamewelcome/:id/:name',
      name: 'betgamewelcome',
      component: BetgameWelcome
    },
    {
      path: '/join/:id/:code',
      name: 'join',
      component: BetgameWelcome
    },
     */
    {
      path: '/tippen/:user/:betgame/:matchday',
      name: 'tippen',
      component: BetgameTippen,
      meta: { requiresAuth: true }
    },
    /*
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: UnsubscribeEmailNotifications
    },
    {
      path: '/betgameedit',
      name: 'betgameedit',
      component: BetgameEdit,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgamedelete',
      name: 'betgamedelete',
      component: BetgameDelete,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditname',
      name: 'betgameeditname',
      component: BetgameEditName,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditdescription',
      name: 'betgameeditdescription',
      component: BetgameEditDescription,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditpoints',
      name: 'betgameeditpoints',
      component: BetgameEditPoints,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditpublic',
      name: 'betgameeditpublic',
      component: BetgameEditPublic,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestions',
      name: 'betgameeditspecialquestions',
      component: BetgameEditSpecialQuestions,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestiondetail',
      name: 'betgameeditspecialquestiondetail',
      component: BetgameEditSpecialQuestionDetail,
      meta: { requiresAuth: true }
    },
    {
      path: '/betgameeditspecialquestionanswers',
      name: 'betgameeditspecialquestionanswers',
      component: BetgameEditSpecialQuestionAnswer,
      meta: { requiresAuth: true }
    },
    {
      path: '/searchbetgame',
      name: 'searchbetgame',
      component: SearchBetgame
    },
    {
      path: '/mybetgames',
      name: 'mybetgames',
      component: MyBetgames
    },
    {
      path: '/sharebetgame',
      name: 'sharebetgame',
      component: ShareBetgame,
      meta: { requiresAuth: true }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: BetgameImprint
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: BetgamePrivacy
    },
    {
      path: '/adminprivacy',
      name: 'adminprivacy',
      component: BetgameAdminPrivacy
    },
    {
      path: '/orderagreement',
      name: 'orderagreement',
      component: BetgameOrderAgreement
    },
    {
      path: '/termsofuse',
      name: 'termsofuse',
      component: BetgameTermsOfUse
    },
    {
      path: '/contact',
      name: 'contact',
      component: BetgameContact
    },
    {
      path: '/faq',
      name: 'faq',
      component: BetgameFAQ
    },
     */
    {
      path: '/start',
      name: 'start',
      component: StartPage,
      meta: { requiresAuth: false }
    },    
    {
      path: '*',
      component: StartPage
    },
  ]
})

router.beforeEach((to, from, next) => {
  Vue.$log.debug('router.beforeEach from: ' + from.name + ' --- to: ' + to.name + ' --- query: ' + JSON.stringify(to.query))
  const id = to.query.id
  const token = to.query.token
  const api = to.query.api
  if(id !== undefined && id !== null && id.length > 0 && token !== undefined && token !== null && token.length > 0 &&
    api !== undefined && api !== null && api.length > 0) {
    store.dispatch('logout').then(() => {
      next()
    })
  }
  
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.loggedIn) {
    Vue.$log.debug('router.beforeEach 000')
    store.dispatch('logout').then(() => {
      next('/')
    })
  }
  if (to.name === 'login' && store.getters.loggedIn) {
    Vue.$log.debug('router.beforeEach 111')
    const clearLastRoute = to.query.clr
    if(clearLastRoute !== undefined && clearLastRoute !== null && clearLastRoute === '1') {
      window.localStorage.removeItem('spmlr')
      //next('/dashboard', query: to.query)
      next({ name: 'dashboard', query: to.query })
    } else {
      const r = window.localStorage.getItem('spmlr')
      if (r !== undefined && r !== null && r.length >= 0 && r !== 'undefined' && r !== 'login' && r !== from.name) {
        next({ name: r, query: to.query })
        return 
        //next('/' + r)
      } else {
        next({ name: 'dashboard', query: to.query })
      }
    }
  }
  if (to.name === 'vr' || to.name === 'validateregistration') {
    if(store.getters.loggedIn && store.state.user.status === 'active' && store.state.user.email === to.query.email) {
      Vue.$toast.open({ message: 'Registrierung abgeschlossen. Viel Spass beim Tippen!', position: 'top', duration: TOAST_DURATION, type: 'success' })
      next({ name: 'dashboard', query: to.query })
    } else {
    }
  }
  if ((to.name === 'register' || to.name === 'login') && store.getters.loggedIn) {
    if(store.state.betgameId) {
      const r = window.localStorage.getItem('spmlr')
      if (r !== undefined && r !== null && r.length >= 0 && r !== 'undefined' && r !== 'login' && r !== 'register' && r !== from.name) {
        next('/' + r)
      } else {
        next({ name: 'dashboard', query: to.query })
      }
    } else {
      next('/mybetgames')
    }
  }
  if(!hasQueryParams(to) && hasQueryParams(from)){
    if(typeof to === 'object' && (typeof to.name === 'string' || typeof to.path === 'string')) {
      next({ name: to, query: from.query });
    } else if(typeof to === 'string') {
      next({ name: to, query: from.query });
    } else {
      next({ name: to, query: to.query })
    }
  } else {
    next({ name: to, query: to.query })
  }
})

router.afterEach((to, from) => {
  store.dispatch('sendDebug', { name: 'router_afterEach', from: from.name || 'empty', to: to.name || 'empty' })
  if(to.name !== undefined && to.name !== 'undefined') {
    window.localStorage.setItem('spmlr', to.name)
  }
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

export default router
