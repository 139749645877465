<template>
  <div :key="componentKey" class="bet" v-bind:style="{ paddingBottom: '64px' }">
  
    <keep-alive>
      <NavigationBar id="betNavigationBarId" ref="betNavigationBarRef">
        <template v-slot:center>
          <div class="navigation_title--headline">
            {{ $t('menu.bet') }}
          </div>
          <div v-on:click="opemMatchdaySelect" class="navigation_title--subline">
            {{ matchdayText }}
          </div>
        </template>
        <template v-slot:right>
          <CalendarIcon v-on:click.native="opemMatchdaySelect" style="padding-right: 0px;" class="navigation_icon"></CalendarIcon>
          <DetailViewIcon v-if="show" v-on:click.native="switchView" class="navigation_icon"></DetailViewIcon>
          <DetailViewActiveIcon v-if="!show" v-on:click.native="switchView" class="navigation_icon"></DetailViewActiveIcon>
        </template>
      </NavigationBar>
    </keep-alive>

    <div v-if="this.hideHint === 0 && isKORound && matches && matches.length > 0" class="betMatchList"
         style="padding: 0px; margin-top: 0px; margin-bottom: 0px;">
      <div style="text-align: center; display: block; border-radius: 10px; border: #ffffff 4px solid; padding: 5px; background-color: white; 
               margin: 16px 16px 0px 16px; max-width: 576px;">
        <div class="dashboard-text" style="padding: 2px;">
          <p style="color: #5F5D61">{{ $t('rules.place_bet_paragraph_3') }}</p>
          <button @click="doHideHint(1)" style="margin-top: 10px;" id="ko_round_bet_hint_button">{{
              $t('hide_hint')
            }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="isKORound && matches && matches.length === 0" class="betMatchList"
         style="padding: 0px; margin-top: 0px; margin-bottom: 0px;">
      <div style="text-align: center; display: block; border-radius: 10px; border: #ffffff 4px solid; padding: 5px; background-color: white; 
               margin: 16px 16px 0px 16px; max-width: 576px;">
        <div class="dashboard-text" style="padding: 2px;">
          <p style="color: #5F5D61">{{ $t('ko_round_matches_not_scheduled') }}</p>
          <router-link :to="{name: 'tournament'}">
            <button style="margin-top: 10px;" id="ko_round_matches_not_scheduled">{{ $t('menu.tournament_groups_schedule') }}</button>
          </router-link>
        </div>
      </div>
    </div>
    
    <div class="bet_content">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :matchday="matchday"
                   @opemMatchdaySelect="opemMatchdaySelect"
                   class="tab"></component>
      </keep-alive>
    </div>

    <div class="navigation_bottom" v-if="show || !show">
      <div class="navigation_bottom--left" v-on:click="decrement">
        <img src="../assets/icons/ic_16_back.svg">
      </div>
      <div class="navigation_bottom--center" variant="outline-primary" v-on:click="opemMatchdaySelect">
        {{ $t('select_matchday') }}
      </div>
      <div class="navigation_bottom--right" v-on:click="increment">
        <img src="../assets/icons/ic_16_next.svg">
      </div>
    </div>
  
    <!-- Matchday Select Code -->
    <modal name="matchday-select" id="matchday-select" :scrollable="true" width="100%" height="auto">
      <div class="menu_header">
          <div @click="$modal.hide('matchday-select')" class="burger_menu">
              <span></span>
              <span></span>
          </div>
          <span class="menu_header-title">{{ $t('select_matchday') }}</span>
      </div>
      <div class="matchday-select-list">
        <button v-for="md in matchdayCount" :key="md"
              v-bind:class="{ selected_matchday: md === currentMatchday }"
              v-on:click="matchdaySelected(md)">{{ md }}</button>
      </div>
    </modal>
    
  </div>
</template>

<script>
import BetMatchList from '@/components/BetMatchList.vue'
import BetMatchSlider from '@/components/BetMatchSlider.vue'
import { isNumeric, isNotNull } from '../vuex/helpers'
import NavigationBar from './../components/NavigationBar'
import DetailViewIcon from './../components/icons/DetailViewIcon'
import DetailViewActiveIcon from './../components/icons/DetailViewActiveIcon'
import CalendarIcon from './../components/icons/CalendarIcon'
import Vue from 'vue'
export default {
  data() {
    return {
      show: true,
      matchday: 1,
      componentKey: 0,
      hideHint: 0
    }
  },
  components: {
    BetMatchList, BetMatchSlider, NavigationBar, DetailViewIcon, DetailViewActiveIcon, CalendarIcon
  },
  beforeCreate() {
    Vue.$log.debug('bet beforeCreate')
  },
  mounted() {
    Vue.$log.debug('bet mounted')
    if(this.$store.state.user && this.$store.state.betgameId) {
      this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
        this.componentKey += 1
      })
    }
  },
  created () {
    Vue.$log.debug('bet created ' + this.$route.params.matchday)
    /*
    if (this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
      this.$store.dispatch('fetchAllBets', this.$store.state.betgameId)
    }
     */
    
    const md = this.$route.params.matchday
    if(isNumeric(md) && parseInt(md) > 0 && parseInt(md) < 100) {
      this.matchday = parseInt(md)
    } else {
      if(this.$store.state.matches.length > 0) {
        const t = this.$store.getters.getClosestFutureMatch
        if(t !== undefined) {
          const nextMatchday = t.matchday
          if(nextMatchday === undefined) {
            this.matchday = this.$store.state.currentMatchdayBet
          } else {
            this.matchday = nextMatchday
          }
        }
      }
    }

    this.hideHint = this.getBGValue('spmhh') || 0
    
    const lastFetchSchedule = this.getBGValue('spmlfs')
    const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
    if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
      if(this.$store.state.leagueId > 0) {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
      }
    }
  
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }
  },
  computed: {
    matches () {
      const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
        return Date.parse(a.matchdate) - Date.parse(b.matchdate)
      })
      return m
    },
    isKORound() {
      if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 107) {
        if(this.matchday > 3) {
          return true
        }
      }
      if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 139) {
        if(this.matchday > 3) {
          return true
        }
      }
      if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110) {
        if(this.matchday > 3) {
          return true
        }
      }
      return false
    },
    matchdayText() {
      if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19) {
        const nextMatchday = this.matchday
        if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_of_16_first_legs')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_of_16_second_legs')
        } else if(nextMatchday === 9) {
          return this.$i18n.t('ko_round_quarter_finals_first_legs')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('ko_round_quarter_finals_second_legs')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('ko_round_semi_finals_first_legs')
        } else if(nextMatchday === 12) {
          return this.$i18n.t('ko_round_semi_finals_second_legs')
        } else if(nextMatchday === 13) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 107) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 139) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_final')
        }
      }
      return this.$i18n.t('matchday', { matchday: this.matchday })
    },
    switchViewAvailable() {
      if(this.$store.state.betgame.competition !== undefined && this.$store.state.betgame.competition !== null) {
        return this.$store.state.betgame.competition.type === 'LEAGUE'
      }
      return false
    },
    currentTabComponent: function() {
      if (this.show) {
        return 'BetMatchList'
      } else {
        return 'BetMatchSlider'
      }
    },
    matchdayCount() {
      return this.$store.state.matchdayCount
    },
    currentMatchday() {
      return this.matchday
    }
  },
  methods: {
    doHideHint(val) {
      this.hideHint = val
      this.setBGValue('spmhh', val)
    },
    switchView() {
      this.show = !this.show
      if(this.show) {
        this.$gtag.pageview({
          page_title: 'bet',
          page_path: '/bet',
          page_location: window.location.href
        })
      } else {
        this.$gtag.pageview({
          page_title: 'betslider',
          page_path: '/betslider',
          page_location: window.location.href + 'slider'
        })
      }
      document.getElementById("app").scrollIntoView()
      this.componentKey += 1
    },
    opemMatchdaySelect() {
      document.getElementById("app").scrollIntoView()
      this.$modal.show('matchday-select');
    },
    matchdaySelected (m) {
      Vue.$log.debug('bet matchdaySelected ' + m)
      this.matchday = m
      this.$modal.hide('matchday-select');
      document.getElementById("app").scrollIntoView()
      this.componentKey += 1
    },
    increment () {
      if (this.matchday < this.$store.state.matchdayCount) {
        this.matchday++
      } else {
        this.matchday = 1
      }
    },
    decrement () {
      if (this.matchday > 1) {
        this.matchday--
      } else {
        this.matchday = this.$store.state.matchdayCount
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .bet {
    padding: 0;
    display: block;
  }
  
  .betMatchList {
      display: block;
      padding: 24px 16px 16px;
      max-width: 576px;
      margin: 0px auto;

      @media screen and (min-width: 768px) {
        padding: 50px 16px;
      }
  }

  .navigation_bottom {
    margin-left: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  /* Matchday Select Code */
  .burger_menu {
    width: 64px;
    height: 64px;
    position: relative;
    cursor: pointer;

    span {
        width: 16px;
        height: 2px;
        position: absolute;
        left: 16px;
        top: 31px;
        background: $colorGreyDark;

        &:first-child {
            top: 31px;
            transform: rotate(45deg);
        }

        &:last-child {
            top: 31px;
            transform: rotate(-45deg);
        }
    }
  }
  .menu_header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      height: 64px;
      align-items: center;
      background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

      &-title {
        font-size: 17px;
        color: $colorGreyDark;
        text-align: center;
        justify-content: center;
        display: flex;
        flex: 1;
      }
  }
  .matchday-select-list {
    padding: 64px 8px 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 576px;
    margin: 0px auto;

    button {
      background: rgba($colorGreyDark, .04);
      width: calc(20% - 16px);
      box-shadow: inset 0 0 0 1px rgba(95,93,97,0.08);
      border-radius: 4px;
      color: $colorGreyDark;
      padding: 0;
      display: flex;
      flex: 1 0 calc(20% - 16px);
      flex-grow: 0;
      margin: 8px 8px;
      justify-content: center;
      align-items: center;
      position: relative;

      @media screen and (min-width: 768px) {
        width: calc(16.3% - 16px);
        flex: 1 0 calc(16.3% - 16px);
        flex-grow: 0;
      }

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }
</style>
