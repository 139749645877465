<template>
    <div :key="componentKey" class="navigation">
        <div id="left">
            <slot name="left">
                <div v-if="loggedIn" v-on:click="openMenu" class="burger_menu">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <img v-else v-on:click="goBackClicked" src="../assets/icons/ic_16_back.svg" class="back" alt="">
            </slot>
        </div>
        <div id="center" class="navigation_title">
            <slot name="center">
            </slot>
        </div>
        <div id="right">
            <slot name="right">
            </slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'NavigationBar',
        data () {
          return {
            showNavbar: true,
            lastScrollPosition: 0,
            componentKey: 0
          }
        },
        mounted () {
          //window.addEventListener('scroll', this.onScroll)
          this.componentKey += 1
        },
        beforeDestroy () {
          //window.removeEventListener('scroll', this.onScroll)
        },
        computed: {
            loggedIn() {
                return this.$store.getters.loggedIn
            }
        },
        methods: {
          openMenu() {
            this.$modal.toggle('betgame-menu')
          },
          onScroll () {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
              return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 64) {
              return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
          },
          homeClicked() {
            this.$router.push('/')
          },
          openProfile() {
            this.$router.push({ name: 'profile' })
          },
          goBackClicked() {
            this.$router.go(-1)
          }
        }
    }
</script>

<style lang="scss">
    
    .navigation.navigation--hidden {
        box-shadow: none;
        transform: translate3d(0, -100%, 0);
    }
    
    .navigation {
        display: flex;
        background: $colorWhite;
        position: fixed;
        align-items: center;
        text-align: center;
        top: 0;
        left: 0;
        min-height: 64px;
        width: 100%;
        box-shadow: inset 0 -1px 0 0 rgba(95,93,97,0.24);
        z-index: 100;
        transform: translate3d(0, 0, 0);
        transition: 0.3s all ease-out;

        .burger_menu {
            width: 64px;
            height: 64px;
            position: relative;
            cursor: pointer;

            span {
                width: 16px;
                height: 2px;
                position: absolute;
                left: 16px;
                top: 31px;
                background: $colorGreyDark;

                &:first-child {
                    top: 26px;
                }

                &:last-child {
                    top: 36px;
                }
            }
        }

        .back {
            padding: 24px 32px 24px 16px;
            cursor: pointer;
        }

        .navigation_title {
            justify-content: center;
            color: $colorGreyDark;
            flex-direction: column;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &--headline{
                font-size: 17px;
                line-height: 19px;
                margin-bottom: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &--subline {
                font-size: 14px;
                line-height: 17px;
                color: $colorGreyMid;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .navigation_icon{
            width: 24px;
            height: 24px;
            padding: 20px 16px 20px 24px;
            cursor: pointer;

            &.small {
                padding: 20px 8px;

                &:last-child {
                    padding: 20px 16px 20px 8px;
                }
            }
        }

        #left {
            display: flex;
            flex: 1;
        }
        #right {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }
    }
</style>
