<template>
    <thead :key="componentKey">

        <tr>
            <th class="col_pos"></th>
            <th class="col_user"></th>
            <th v-on:click="sortByTotal" class="col_value col_value--gesamt" :class="{ sort: sorting === 1 }"><span>{{ $t('total') }}</span></th>
            <th v-on:click="sortByMatchday" class="col_value col_value--spieltag" :class="{ sort: sorting === 2 }"><span>{{ $t('matchday_short') }}</span></th>
            <!--
            <th v-on:click="sortByTotal" class="col_value"><span :class="{ sort: sorting === 1 }">Gesamt </span><span v-if="sorting === 1" class="sort_arrow">&darr;</span></th>
            <th v-on:click="sortByMatchday" class="col_value"><span :class="{ sort: sorting === 2 }">Spieltag </span><span v-if="sorting === 2" class="sort_arrow">&darr;</span></th>
            -->
            <!--
            <th v-on:click="toggleSorting" v-if="sorting === 2 && matchRunning" class="col_value live">Live<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Gesamt)</span></th>
            <th v-on:click="toggleSorting" v-else-if="sorting === 2" class="col_value">Spieltag<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Gesamt)</span></th>
            <th v-on:click="toggleSorting" v-if="sorting === 1 && matchRunning" class="col_value">Gesamt<span style="color: red; font-size: smaller; vertical-align: center"> (Live)</span></th>
            <th v-on:click="toggleSorting" v-else-if="sorting === 1" class="col_value">Gesamt<span style="color: #ACACAC; font-size: smaller; vertical-align: center"> (Spieltag)</span></th>
            -->
            <th class="col_teams" v-for="(match, index) in matches" :key="match.id" v-bind:class="index % 2 === 0 ? 'col_white' : 'col_grey'">
                <span>{{ getTeamName(match.fk_team_home, match.team_home) }}</span>
                <span>{{ getTeamName(match.fk_team_guest, match.team_guest) }}</span>
                <span v-if="isNumeric(match.goalshome) && isNumeric(match.goalsguest)">{{ match.goalshome }}:{{ match.goalsguest }}</span>
                <span v-else-if="isNumeric(match.livegoalshome) && isNumeric(match.livegoalsguest)" class="live_result">{{ match.livegoalshome }}:{{ match.livegoalsguest }}</span>
            </th>
        </tr>
        <!--<tr>
            <th></th>
            <th class="col_user"></th>
            <th class="col_value"></th>
            <th class="col_value"></th>
            <th v-for="(match, index) in matches" :key="match.id" v-bind:class="index % 2 == 0 ? 'col_grey' : 'col_white'"
                style="white-space: nowrap;padding-left: 5px; padding-right: 5px">{{ getResult(match) }}</th>
        </tr>-->
    </thead>
</template>

<script>
    export default {
        name: 'RankingsDetailTableHeader',
        data() {
          return {
            componentKey: 0
          };
        },
        props: {
          searchString: {
              type: String,
              default: () => {
                return ''
              }
            },
            matches: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        computed: {
          sorting() {
            return this.$store.state.matchdayRankingSorting
          },
          matchday() {
            return this.$store.state.currentMatchdayRankingByMatchday
          },
          matchRunning() {
            if(this.matches === null) {
              return false
            }
            for (var i = 0; i < this.matches.length; i++) {
              const match = this.matches[i]
              if (this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
                return true
              }
            }
          },
        },
        methods: {
            sortByTotal() {
              if(this.$store.state.matchdayRankingSorting === 2) {
                this.$emit('sortingChanged', {})
                const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  if (this.$store.state.leagueId && this.$store.state.leagueId > 0) {
                    this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                        this.componentKey += 1
                      })
                    })
                  }
                }
                this.$store.dispatch('setMatchdayRakingSorting', 1)
              }
            },
            sortByMatchday() {
              if(this.$store.state.matchdayRankingSorting === 1) {
                this.$emit('sortingChanged', {})
                const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
                const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
                if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
                  this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
                  if (this.$store.state.leagueId && this.$store.state.leagueId > 0) {
                    this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
                      this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                        this.componentKey += 1
                      })
                    })
                  }
                }
                this.$store.dispatch('setMatchdayRakingSorting', 2)
              }
            },
            refresh() {
              this.$emit('refreshRankingByMatchday', {})
            },
            getResult(match) {
                if(match.livegoalshome !== null && match.livegoalsguest !== null &&
                        match.goalshome === null && match.goalsguest === null) {
                    return match.livegoalshome + ' : ' + match.livegoalsguest
                } else if(match.goalshome !== null && match.goalsguest !== null) {
                    return match.goalshome + ' : ' + match.goalsguest
                }
                return '- : -'
            },
            getTeamName(teamId, teamName) {
              const team = this.$store.getters.getTeamById(teamId)
              if(team != null && team.short !== null && team.short.length > 0) {
                return team.short
              }
              return teamName
            }
        },
        filters: {
            subStr: function(string) {
                return string.substring(0,6);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .col_teams {
        background: none;
        text-align: center;
        padding: 14px 8px 12px;
        max-width: 40px;

        span {
            display: block;
            width: 24px;
            margin: 0 auto;

            &:first-child {
                border-bottom: 1px dashed $colorGreyMid;
            }
        }

        &:last-child {
            padding-right: 16px;
        }
    }
    .col_value {
        text-align: center;
        overflow: hidden;
        padding: 0 4px;
        border-right: 1px solid rgba($colorGreyLight, 0.08);

      &--gesamt, &--spieltag {
        span {
          font-size: 9px;
          font-weight: 600;
          text-transform: uppercase;
          //color: rgba($colorWhite, .64);
          background: var(--color-primary);
          padding: 4px;
          border-radius: 2px;
          opacity: .48;
          cursor: pointer;
        }
      }

      &--gesamt {
        padding-left: 4px;

        span {
          border-radius: 2px 0 0 2px;
        }
      }

      &--spieltag {
        padding-right: 4px;

        span {
          border-radius: 0 2px 2px 0;
        }
      }

      &.sort {
        span {
          //color: $colorWhite;
          background: var(--color-primary);
          opacity: 1;
        }
      }

      &.live {
        color: $colorNegative;
      }
    }
    
    .col_user {
        text-align: left;
        overflow: hidden;
    }

    .reload {
        font-weight: 500;
        color: $colorPrimary;
        padding-left: 5px;
        img {
            margin-top: 2px;
        }
    }

    thead {

        tr {
            background: none;

            th{
                font-size: 11px;

                &:last-child {
                    padding-right: 8px;
                }
            }
        }
    }
    
    .live_result {
        color: $colorNegative;
    }

    .sort {
        position: relative;

        &:after {
            color: $colorPrimary;
            content: '↓';
        }
    }

    .sort_arrow {
        color: $colorPrimary;
    }
</style>
