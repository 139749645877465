/* eslint-disable */
<template>
  <div class="schedule" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="leagueTableNavigationBarId" ref="leagueTableNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.league_table') }}
        </template>
        <template v-slot:right>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <LeagueTable :positions="positions"></LeagueTable>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeagueTable from '../components/LeagueTable'
import NavigationBar from './../components/NavigationBar'
import Menu from '../components/Menu'

export default {
  components: { LeagueTable, NavigationBar, Menu },
  created() {
    //if (this.$store.state.positions.length === 0) {
      this.$store.dispatch('fetchTable', this.$store.state.leagueId)
    //}
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }
  },
  computed: mapState(['positions']),
  methods: {
    openMenu () {
      this.$modal.show('betgame-menu')
    },
    showSchedule() {
      this.$router.push({ name: 'schedule' })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
