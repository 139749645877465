<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: #44AD34;">
        <path d="M20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L20,0 Z M18,17 L6,17 C5.44771525,17 5,17.4477153 5,18 C5,18.5522847 5.44771525,19 6,19 L18,19 C18.5522847,19 19,18.5522847 19,18 C19,17.4477153 18.5522847,17 18,17 Z M6,13 C5.44771525,13 5,13.4477153 5,14 C5,14.5522847 5.44771525,15 6,15 L18,15 C18.5522847,15 19,14.5522847 19,14 C19,13.4477153 18.5522847,13 18,13 L6,13 Z M17,4 L7,4 C5.8954305,4 5,4.8954305 5,6 L5,6 L5,9 C5,10.1045695 5.8954305,11 7,11 L7,11 L17,11 C18.1045695,11 19,10.1045695 19,9 L19,9 L19,6 C19,4.8954305 18.1045695,4 17,4 L17,4 Z M17,6 L17,9 L7,9 L7,6 L17,6 Z" id="Combined-Shape"></path>
    </svg>
</template>

<script>
  export default {
    name: 'DetailViewActiveIcon'
  }
</script>
