<template>
  <div class="rankings" v-bind:style="{ paddingBottom: '64px' }">
  
    <keep-alive>
      <NavigationBar id="rankingsMatchdayNavigationBarId" ref="rankingsMatchdayNavigationBarRef">
        <template v-slot:center>
          <div class="navigation_title--headline">
            {{ $t('menu.matchday_ranking') }}
          </div>
          <div v-on:click="selectMatchday" class="navigation_title--subline">
            {{ matchdayText }}
          </div>
        </template>
        <template v-slot:right>
          <div v-if="matchRunning" class="navigation_icon" v-bind:class="{ small: matchRunning }" v-on:click="refreshRankingByMatchday">
            <UpdateIcon></UpdateIcon>
          </div>
          <img v-on:click="toggleSearch" class="navigation_icon" v-bind:class="{ small: matchRunning }" src="../assets/icons/ic_24_search.svg">
        </template>
      </NavigationBar>
    </keep-alive>

    <div class="vld-parent">
      <loading :active.sync="isLoading"
               :can-cancel="false"
               :color="this.$store.state.colorPrimary"
               :opacity="opacity"
               :is-full-page="isFullPage">
        <div style="margin-bottom: 20px; font-size: 18pt;" slot="before">Lade...</div>
      </loading>
    </div>

    <div v-if="showOdds" style="background: white; border-radius: 10px; flex: calc(50% - 12px); margin: 8px auto 8px; max-width: 576px; display: block;">
      <div style="margin: 10px 40px 0px 40px; padding: 5px;">
        <!-- <span class="bwin_ad_marker">-W-</span> -->
        <p style="text-align: center; font-size: small; line-height: 1.2rem;">Und danach wieder <b>ärgern</b>, dass du nicht <b>gewettet</b> hast, ne?! 😤</p>
        <div style="margin-top: 8px; text-align: center;" class="bwin_button" v-on:click="deepLinkClicked">
          <a :href="deepLink" target="_blank">DIESMAL NICHT!</a>
        </div>
        <div class="bet-box-infotext">
          Lizenziert (Whitelist) | 18+ | Suchtrisiko | <a target="_blank" href="https://buwei.de" style="color: darkgray">buwei.de</a> | -w-
        </div>
      </div>
    </div>
    
    <RankingsDetailTable v-visibility-change="visibilityChange" ref="rankingsDetailTable" :searchActive="searchActive" :key="matchday" @sortingChanged="sortingChanged" @refreshRankingByMatchday="refreshRankingByMatchday"></RankingsDetailTable>
  
    <div class="navigation_bottom">
      <div class="navigation_bottom--left" v-on:click="decrement">
        <img src="../assets/icons/ic_16_back.svg">
      </div>
      <div class="navigation_bottom--center" variant="outline-primary" v-on:click="selectMatchday">
        {{ $t('select_matchday') }}
      </div>
      <div class="navigation_bottom--right" v-on:click="increment">
        <img src="../assets/icons/ic_16_next.svg">
      </div>
    </div>
    
    <!-- Matchday Select Code -->
    <modal name="matchday-select" id="matchday-select" :scrollable="true" width="100%" height="auto">
      <div class="menu_header">
          <div @click="$modal.hide('matchday-select')" class="burger_menu">
              <span></span>
              <span></span>
          </div>
          <span class="menu_header-title">{{ $t('select_matchday') }}</span>
      </div>
      <div class="matchday-select-list">
        <button v-for="md in matchdayCount" :key="md"
              v-bind:class="{ selected_matchday: md === matchday }"
              v-on:click="matchdaySelected(md)">{{ md }}</button>
      </div>
    </modal>
    
  </div>
</template>

<script>
  import RankingsDetailTable from '../components/RankingsDetailTable'
  import NavigationBar from './../components/NavigationBar'
  import Menu from '../components/Menu'
  import { TOAST_DURATION, TOP_TEN } from '../main'
  import UpdateIcon from './../components/icons/UpdateIcon'
  import Loading from 'vue-loading-overlay'
  export default {
    name: 'rankingsbymatchday',
    data() {
      return {
        deepLink: 'https://sports.bwin.de/de/sports/fussball-4/wetten?wm=5047188',
        searchActive: false,
        isLoading: false,
        opacity: 0.9,
        isFullPage: true
      };
    },
    components: { UpdateIcon, RankingsDetailTable, NavigationBar, Menu, Loading },
    created() {
  
      const md = this.$route.params.matchday
      if(this.isNumeric(md)) {
        //this.matchday = md
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', md)
      } else {
        //this.matchday = this.$store.state.currentMatchday
      }
  
      if(this.$store.state.leagueId) {
        const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
        const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
                this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                this.setBGValue('lastFetchLiveRanking', new Date())
              })
            })
          })
        }
      } else {
          this.$store.dispatch('fetchBetgameLeague', this.$store.state.betgameId)
      }
      
      const lastFetchSchedule = this.getBGValue('spmlfs')
      const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
      if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
        if( this.$store.state.leagueId > 0)  {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        }
      }
      
      if (this.$store.state.teams.length === 0) {
        this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
      }
    },
    mounted () {
      if(this.$store.state.user && this.$store.state.betgameId) {
        this.$store.dispatch('fetchBets', this.$store.state.user.id).then(() => {
          this.componentKey += 1
        })
      }
    },
    computed: {
      showOdds() {
        return false 
        /*
        if(this.$store.state.betgame && this.$store.state.user) {
          return !this.countryBlocked() && this.$store.state.betgame.hide_ads === false && 
              this.$store.state.user.isadult === true && this.$store.state.spAds === 'bet'
        }
        return false
        */
      },
      matchdayText() {
        if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19) {
          const nextMatchday = this.matchday
          if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_of_16_first_legs')
          } else if(nextMatchday === 8) {
            return this.$i18n.t('ko_round_of_16_second_legs')
          } else if(nextMatchday === 9) {
            return this.$i18n.t('ko_round_quarter_finals_first_legs')
          } else if(nextMatchday === 10) {
            return this.$i18n.t('ko_round_quarter_finals_second_legs')
          } else if(nextMatchday === 11) {
            return this.$i18n.t('ko_round_semi_finals_first_legs')
          } else if(nextMatchday === 12) {
            return this.$i18n.t('ko_round_semi_finals_second_legs')
          } else if(nextMatchday === 13) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 107) {
          const nextMatchday = this.matchday
          if(nextMatchday === 1) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 2) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 3) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 4) {
            return this.$i18n.t('em_ko_round_of_16')
          } else if(nextMatchday === 5) {
            return this.$i18n.t('em_ko_round_quarter_finals')
          } else if(nextMatchday === 6) {
            return this.$i18n.t('em_ko_round_semi_finals')
          } else if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 139) {
          const nextMatchday = this.matchday
          if(nextMatchday === 1) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 2) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 3) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 4) {
            return this.$i18n.t('em_ko_round_of_16')
          } else if(nextMatchday === 5) {
            return this.$i18n.t('em_ko_round_quarter_finals')
          } else if(nextMatchday === 6) {
            return this.$i18n.t('em_ko_round_semi_finals')
          } else if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_third_place_short')
          } else if(nextMatchday === 8) {
            return this.$i18n.t('ko_round_final')
          }
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110) {
          const nextMatchday = this.matchday
          if(nextMatchday === 1) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 2) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 3) {
            return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
          } else if(nextMatchday === 4) {
            return this.$i18n.t('em_ko_round_of_16')
          } else if(nextMatchday === 5) {
            return this.$i18n.t('em_ko_round_quarter_finals')
          } else if(nextMatchday === 6) {
            return this.$i18n.t('em_ko_round_semi_finals')
          } else if(nextMatchday === 7) {
            return this.$i18n.t('ko_round_third_place_short')
          } else if(nextMatchday === 8) {
            return this.$i18n.t('ko_round_final')
          }
        }
        return this.$i18n.t('matchday', { matchday: this.matchday })
      },
      matchday() {
        return this.$store.state.currentMatchdayRankingByMatchday
      },
      matchdayCount () {
        return this.$store.state.matchdayCount
      },
      matchRunning() {
        if(this.$store.state.matches === null || this.$store.state.matches === undefined) {
          return false
        }
        for (var i = 0; i < this.$store.state.matches.length; i++) {
          const match = this.$store.state.matches[i]
          if (match.matchday === this.matchday && this.isNumeric(match.livegoalshome) && this.isNumeric(match.livegoalsguest)) {
            return true
          }
        }
      }
    },
    methods: {
      visibilityChange(evt, hidden) {
        if(hidden === false && this.$store.state.leagueId && this.$store.state.leagueId > 0) {
          const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
          const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
          if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length === 0 ||
              this.$store.state.allbets == null || this.$store.state.allbets.length === 0) {
            this.isLoading = true
            const that = this
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                  this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                    this.setBGValue('lastFetchLiveRanking', new Date())
                  }).finally( function () {
                    that.isLoading = false
                  })
                })
              })
            })
          }
        }        
      },      
      deepLinkClicked() {
        if(this.deepLink) {
          this.$gtag.event('Kombiwette', {
            'event_category': 'bwin',
            'event_label': this.matchday ? '' + this.matchday + '. Spieltag' : '',
            'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
            'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
            'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
          })
          if(window.parent) {
            window.parent.open(this.deepLink, '_blank')
          } else {
            window.open(this.deepLink, '_blank')
          }
        }
      },
      sortingChanged() {
        this.searchActive = false
      },
      toggleSearch() {
        this.searchActive = !this.searchActive
        this.$refs.rankingsDetailTable.clearSearchString()
      },
      refreshRankingByMatchday() {
        //this.$toast.open({ message:  'Aktualisiere Tippübersicht...', position: 'top', duration: TOAST_DURATION, type: 'info' })
        //const lastFetchLiveRanking = this.getBGValue('lastFetchLiveRanking')
        //const duration1 = (new Date() - new Date(lastFetchLiveRanking)) / 1000
        //if (duration1 > this.$store.getters.getCachingDuration || this.$store.state.liveRankings == null || this.$store.state.liveRankings.length == 0 ||
        //        this.$store.state.allbets == null || this.$store.state.allbets.length == 0) {
          if(this.$store.state.leagueId && this.$store.state.leagueId > 0) {
            this.isLoading = true
            const that = this
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
              this.$store.dispatch('fetchLiveRanking', [this.$store.state.betgameId, this.$store.state.leagueId, 0]).then(() => {
                this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
                  this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
                    this.setBGValue('lastFetchLiveRanking', new Date())
                  })
                })
              })
            }).finally( function () {
              that.isLoading = false
            })
          }
        //}
  
        //const lastFetchSchedule = this.getBGValue('spmlfs')
        //const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
        //if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length == 0) {
        //  this.$store.dispatch('fetchMatchdaySchedule', [this.$store.state.leagueId, this.matchday]).then(() => {
        //    console.log('############################## refreshRankingByMatchday 222')
        //  })
        //}
      },
      openMenu() {
        this.$modal.show('betgame-menu')
      },
      selectMatchday() {
        this.$modal.show('matchday-select');
      },
      increment () {
        if (this.matchday < this.$store.state.matchdayCount) {
          //this.matchday++
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday + 1)
        } else {
          //this.matchday = 1
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', 1)
        }
        if(this.$store.state.matchdayRankings.length > this.matchday) {
          const mdr = this.$store.state.matchdayRankings[this.matchday]
          if (mdr === undefined || mdr === null) {
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
              })
            })
          } else {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          }
        } else {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        }
      },
      decrement () {
        if (this.matchday > 1) {
          //this.matchday--
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.matchday - 1)
        } else {
          //this.matchday = this.$store.state.matchdayCount
          this.$store.dispatch('setCurrentMatchdayRankingByMatchday', this.$store.state.matchdayCount)
        }
        if(this.$store.state.matchdayRankings.length > this.matchday) {
          const mdr = this.$store.state.matchdayRankings[this.matchday]
          if (mdr === undefined || mdr === null) {
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
              })
            })
          } else {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          }
        } else {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        }
      },
      matchdaySelected (m) {
        //this.matchday = m
        this.$store.dispatch('setCurrentMatchdayRankingByMatchday', m)
        this.$modal.hide('matchday-select');
        if(this.$store.state.matchdayRankings.length > this.matchday) {
          const mdr = this.$store.state.matchdayRankings[this.matchday]
          if (mdr === undefined || mdr === null) {
            this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
              this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
              })
            })
          } else {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          }
        } else {
          this.$store.dispatch('fetchMatchdayRanking', [this.$store.state.betgameId, this.$store.state.leagueId, this.matchday]).then(() => {
            this.$store.dispatch('fetchBetsForMatchdayRanking', [this.$store.state.betgameId, this.matchday]).then(() => {
            })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
  }
  #bg_rankings_table {
    width: 100%;
  }
  .col_user {
    text-align: left;
    overflow: hidden;
    width: 50%;
  }
  .col_value {
    text-align: right;
    overflow: hidden;
  }
  .col_points {
    margin-right: 10px;
  }

  .navigation_bottom {
    margin: 0 -16px;
  }

  /* Matchday Select Code */
  .burger_menu {
    width: 64px;
    height: 64px;
    position: relative;
    cursor: pointer;

    span {
        width: 16px;
        height: 2px;
        position: absolute;
        left: 16px;
        top: 31px;
        background: $colorGreyDark;

        &:first-child {
            top: 31px;
            transform: rotate(45deg);
        }

        &:last-child {
            top: 31px;
            transform: rotate(-45deg);
        }
    }
  }
  .menu_header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      height: 64px;
      align-items: center;
      background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

      &-title {
        font-size: 17px;
        color: $colorGreyDark;
        text-align: center;
        justify-content: center;
        display: flex;
        flex: 1;
      }
  }
  .matchday-select-list {
    padding: 64px 8px 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 576px;
    margin: 0px auto;

    button {
      background: rgba($colorGreyDark, .04);
      width: calc(20% - 16px);
      box-shadow: inset 0 0 0 1px rgba(95,93,97,0.08);
      border-radius: 4px;
      color: $colorGreyDark;
      padding: 0;
      display: flex;
      flex: 1 0 calc(20% - 16px);
      flex-grow: 0;
      margin: 8px 8px;
      justify-content: center;
      align-items: center;
      position: relative;

      @media screen and (min-width: 768px) {
        width: calc(16.3% - 16px);
        flex: 1 0 calc(16.3% - 16px);
        flex-grow: 0;
      }

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }

  .bwin_button {
    background: $colorPrimary;
    font-size: 17px;
    font-weight: 500;
    border: 0;
    border-radius: 6px;
    padding: 6px 10px;
    a {
      color: white;
      text-decoration: none;
    }
  }
  .bet-box-infotext {
    font-size: 9px;
    color: $colorGreyMid;
    text-align: center;
    width: 100%;
    margin-top: 3px;
  
  }
  .bwin_ad_marker {
    position: relative;
    float: right;
    left: 30px;
    font-size: 9px;
    color: rgba($colorGreyLight, .18)
  }
</style>
