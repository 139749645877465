<template>
  <div class="matchdetail_slide">

    <div class="matchdetail_slide-left">
      <img v-if="homeTeamCode && homeTeamCode.length > 6" :src="homeTeamLogo" width="72">
      <div v-else-if="homeTeamCode && homeTeamCode.length <= 6"><flag :iso="homeTeamCode" /></div>
      <svg v-else width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M50.0364685,43.3999585 C50.0352958,43.6607463 50.0347094,43.9140935 50.0347094,44.16 C50.0347094,53.76 51,53.76 51,66 L21,66 C21,54 21.9140861,54 21.9140861,44.16 C21.9140861,43.7462875 21.9125064,43.3131658 21.9093469,42.8606348 C21.4856899,38.7549685 20.8517753,35.1080902 20.0076031,31.92 L19.5442492,32.9466061 C19.1285067,33.8677245 18.0909415,34.33397 17.126135,34.0332221 L10.6131556,32.0030069 C9.59982665,31.6871337 9.00974195,30.6346888 9.26885573,29.6053825 L12.0120635,18.7082344 C12.706492,15.9496791 14.4681327,13.5798802 16.9095684,12.12 L20.1846499,10.1616332 C20.353215,10.0608161 20.5439254,10.0162712 20.7316088,10.0265756 C20.7285897,9.96445474 20.7255649,9.90226289 20.7225342,9.84 L28.2081904,6 C30.8222115,7.5 33.4194814,8.25 36,8.25 C38.5805186,8.25 41.1607203,7.5 43.7406051,6 L50.6741372,9.5567708 C50.8815298,9.6631258 51.0387147,9.83824912 51.1244595,10.0454174 C51.3564058,9.99706417 51.603386,10.0348597 51.8153501,10.1616332 L55.0904316,12.12 C57.5318673,13.5798802 59.293508,15.9496791 59.9879365,18.7082344 L62.7311443,29.6053825 C62.990258,30.6346888 62.4001734,31.6871337 61.3868444,32.0030069 L54.873865,34.0332221 C53.9090585,34.33397 52.8714933,33.8677245 52.4557508,32.9466061 L51.9923969,31.92 C51.1114401,35.2470105 50.459464,39.0736634 50.0364685,43.3999585 L50.0364685,43.3999585 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_home)}"></path>
      </svg>
      {{ getTeamName(match.fk_team_home, match.team_home) }}
    </div>

    <div v-if="betmode === 1" class="matchdetail_slide-center">
      <div class="matchdetail_slide-goals">
        <div class="matchdetail_slide-goals-home">
          {{ (bet && isNumeric(bet.goalshome)) ? bet.goalshome : '-' }}
        </div>
        :
        <div class="matchdetail_slide-goals-guest">
          {{ (bet && isNumeric(bet.goalsguest)) ? bet.goalsguest : '-' }}
        </div>
      </div>
      <div class="matchdetail_slide-date">
        {{ match.matchdate | formatDateSlider }}
      </div>
    </div>

    <div v-else-if="betmode === 2" class="matchdetail_slide-center">
      <div class="bet-input-toto">
        <div @click="totoButtonClicked(1)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 1, 'bet-input-toto_button_win': totoWin(1), 'bet-input-toto_button_lose': totoLose(1)}" class="bet-input-toto_button">
          1
        </div>
        <div @click="totoButtonClicked(0)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 0, 'bet-input-toto_button_win': totoWin(0), 'bet-input-toto_button_lose': totoLose(0)}" class="bet-input-toto_button">
          0
        </div>
        <div @click="totoButtonClicked(2)" :class="{ 'bet-input-toto_button_selected': this.homeBetValue === 2, 'bet-input-toto_button_win': totoWin(2), 'bet-input-toto_button_lose': totoLose(2)}" class="bet-input-toto_button">
          2
        </div>
      </div>
      <div class="matchdetail_slide-date">
        {{ match.matchdate | formatDateSlider }}
      </div>
    </div>

    <div class="matchdetail_slide-right">
      <img v-if="guestTeamCode && guestTeamCode.length > 6" :src="guestTeamLogo" width="72">
      <div v-else-if="guestTeamCode && guestTeamCode.length <= 6" style="font-size: 32px;"><flag :iso="guestTeamCode" /></div>
      <svg v-else width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M50.0364685,43.3999585 C50.0352958,43.6607463 50.0347094,43.9140935 50.0347094,44.16 C50.0347094,53.76 51,53.76 51,66 L21,66 C21,54 21.9140861,54 21.9140861,44.16 C21.9140861,43.7462875 21.9125064,43.3131658 21.9093469,42.8606348 C21.4856899,38.7549685 20.8517753,35.1080902 20.0076031,31.92 L19.5442492,32.9466061 C19.1285067,33.8677245 18.0909415,34.33397 17.126135,34.0332221 L10.6131556,32.0030069 C9.59982665,31.6871337 9.00974195,30.6346888 9.26885573,29.6053825 L12.0120635,18.7082344 C12.706492,15.9496791 14.4681327,13.5798802 16.9095684,12.12 L20.1846499,10.1616332 C20.353215,10.0608161 20.5439254,10.0162712 20.7316088,10.0265756 C20.7285897,9.96445474 20.7255649,9.90226289 20.7225342,9.84 L28.2081904,6 C30.8222115,7.5 33.4194814,8.25 36,8.25 C38.5805186,8.25 41.1607203,7.5 43.7406051,6 L50.6741372,9.5567708 C50.8815298,9.6631258 51.0387147,9.83824912 51.1244595,10.0454174 C51.3564058,9.99706417 51.603386,10.0348597 51.8153501,10.1616332 L55.0904316,12.12 C57.5318673,13.5798802 59.293508,15.9496791 59.9879365,18.7082344 L62.7311443,29.6053825 C62.990258,30.6346888 62.4001734,31.6871337 61.3868444,32.0030069 L54.873865,34.0332221 C53.9090585,34.33397 52.8714933,33.8677245 52.4557508,32.9466061 L51.9923969,31.92 C51.1114401,35.2470105 50.459464,39.0736634 50.0364685,43.3999585 L50.0364685,43.3999585 Z" id="basic-jersey" :style="{'fill': getTeamColor(match.fk_team_guest)}"></path>
      </svg>
      {{ getTeamName(match.fk_team_guest, match.team_guest) }}
    </div>

    <div class="matchdetail_slide-live" v-if="isLive" v-bind:class="{ bet_exact_result: betPoints === pointsBetResult, bet_goal_diff: betPoints === pointsBetGoaldiff, bet_result_tendency: betPoints === pointsBetTendency, bet_wrong_result: betPoints === 0}">
      <div class="matchdetail_slide-live-left">
          <span>
            {{ $t('live') }}
          </span>
      </div>
      <div class="matchdetail_slide-live-center">
        {{ match.livegoalshome }}:{{ match.livegoalsguest }}
      </div>
      <div v-if="playedMinutes < 91" class="matchdetail_slide-live-right">
        {{ playedMinutes }}. {{ $t('minutes_short') }}
      </div>
      <div v-else class="matchdetail_slide-live-right">
        90+ {{ $t('minutes_short') }}
      </div>
    </div>

    <!-- Dieser Bereich soll nur nach Abpfiff angezeigt werden. Punkteanzahl und Hintergrundfarbe müssen dynamisch sein. Die Hintergrundfarbe kann so wie bei EditBetRow.vue über eine Klasse (bspw. ".bet_exact_result") erzeugt werden. -->
    <div v-bind:class="{ bet_exact_result: betPoints === pointsBetResult, bet_goal_diff: betPoints === pointsBetGoaldiff, bet_result_tendency: betPoints === pointsBetTendency, bet_wrong_result: betPoints === 0}"
         class="matchdetail_slide-live" v-if="matchFinished">
      <div class="matchdetail_slide-live-left">
        {{ betPoints }} {{ $t('rankings_table_header_points') }}
      </div>
      <div class="matchdetail_slide-live-center">
        {{ match.goalshome }}:{{ match.goalsguest }}
      </div>
      <div class="matchdetail_slide-live-right">
        {{ $t('match_finished') }}
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BetMatchDetailSlide',
  data () {
    return {
      homeBetValue: null
    }
  },
  props: {
    match: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  mounted() {
    if(this.bet) {
      this.homeBetValue = this.bet.goalshome
    }
  },
  computed: {
    betEditable () {
      const md = this.parseMatchdate(this.match.matchdate)
      if(md < Date.now()) {
        return false;
      }
      if(this.match.status && (this.match.status.trim() === 'annulliert' || this.match.status.trim() === 'abgesagt'
          || this.match.status.trim() === 'verlegt' || this.match.status.trim() === 'Wertung'
          || this.match.status.trim() === 'abgebrochen')) {
        return false
      }
      if(this.isPlaceholderTeam(this.match.fk_team_home) || this.isPlaceholderTeam(this.match.fk_team_guest)) {
        return false
      }
      return !this.isNumeric(this.match.goalshome) && !this.isNumeric(this.match.goalsguest) &&
          !this.isNumeric(this.match.livegoalshome) && !this.isNumeric(this.match.livegoalsguest)
    },
    betmode() {
      return this.$store.state.betgame.bet_mode || 1
    },
    pointsBetResult() {
      return this.$store.state.betgame.points_bet_result
    },
    pointsBetGoaldiff() {
      return this.$store.state.betgame.points_bet_goaldiff
    },
    pointsBetTendency() {
      return this.$store.state.betgame.points_bet_tendency
    },
    homeTeamCode() {
      const l = this.getTeamLogo(this.match.fk_team_home)
      if(l) {
        return l
      }
      return null
    },
    guestTeamCode() {
      const l = this.getTeamLogo(this.match.fk_team_guest)
      if(l) {
        return l
      }
      return null
    },
    homeTeamLogo() {
      const l = this.getTeamLogo(this.match.fk_team_home)
      if(l) {
        return this.getBGValue('spmau') + '/images/jerseys/72/' + l
      }
      return null
    },
    guestTeamLogo() {
      const l = this.getTeamLogo(this.match.fk_team_guest)
      if(l) {
        return this.getBGValue('spmau') + '/images/jerseys/72/' + l
      }
      return null
    },
    betPoints() {
      const b = this.bet
      if(b) {
        return this.getPoints(b, this.match)
      }
      return 0
    },
    bet() {
      const b = this.$store.getters.getBetByBetgameAndMatch(this.$store.state.betgameId, this.match.id)
      if (b != null) {
        return b
      } else {
        const nb = {
          'fk_user': parseInt(this.$store.state.user.id),
          'fk_match': parseInt(this.match.id),
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('addBet', nb)
        return nb
      }
    },
    matchFinished() {
      return this.match && this.isNumeric(this.match.goalshome) && this.isNumeric(this.match.goalsguest)
    },
    isLive () {
      return this.isNumeric(this.match.livegoalshome) && this.isNumeric(this.match.livegoalsguest)
    },
    playedMinutes() {
      //const date = new Date(Date.parse(this.match.matchdate))
      const date = this.parseMatchdate(this.match.matchdate).toDate()
      const diff = Math.abs(new Date() - date)
      var min = Math.ceil(diff / (1000 * 60))
      if(min > 45 && min < 61) {
        return 45
      } else if(min > 60) {
        return min - 15
      }
      return min
    }
  },
  methods: {
    totoWin (betbutton) {
      if (this.betEditable) {
        return false
      }
      if (this.bet.goalshome > 2 || this.bet.goalsguest > 2) {
        return false
      }
      if (this.bet.goalshome === parseInt(betbutton)) {
        if (this.bet.goalshome === 1) {
          if (this.isNumeric(this.match.goalshome) && this.isNumeric(this.match.goalsguest)) {
            return this.match.goalshome > this.match.goalsguest
          } else if (this.isNumeric(this.match.livegoalshome) && this.isNumeric(this.match.livegoalsguest)) {
            return this.match.livegoalshome > this.match.livegoalsguest
          } else {
            return false
          }
        } else if (this.bet.goalshome === 0) {
          if (this.isNumeric(this.match.goalshome) && this.isNumeric(this.match.goalsguest)) {
            return this.match.goalshome === this.match.goalsguest
          } else if (this.isNumeric(this.match.livegoalshome) && this.isNumeric(this.match.livegoalsguest)) {
            return this.match.livegoalshome === this.match.livegoalsguest
          } else {
            return false
          }
        } else if (this.bet.goalshome === 2) {
          if (this.isNumeric(this.match.goalshome) && this.isNumeric(this.match.goalsguest)) {
            return this.match.goalshome < this.match.goalsguest
          } else if (this.isNumeric(this.match.livegoalshome) && this.isNumeric(this.match.livegoalsguest)) {
            return this.match.livegoalshome < this.match.livegoalsguest
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    totoLose(betbutton) {
      if (this.betEditable) {
        return false
      }
      if (this.bet.goalshome > 2 || this.bet.goalsguest > 2) {
        return false
      }
      if (this.bet.goalshome === parseInt(betbutton)) {
        return !this.totoWin(betbutton)
      } else {
        return false
      }
    },
    totoButtonClicked (val) {
      if(this.betEditable) {
        this.homeBetValue = val
        this.saveBet()
      }
    },
    saveBet () {
      if(this.betmode === 2 && this.isNumeric(this.homeBetValue) && this.bet.goalshome !== this.homeBetValue) {
        const bet = {
          'fk_user': this.$store.state.user.id,
          'fk_match': this.match.id,
          'goalshome': parseInt(this.homeBetValue),
          'goalsguest': parseInt(this.homeBetValue),
          'fk_betgame': parseInt(this.$store.state.betgameId)
        }
        this.$store.dispatch('updateBet', bet).then(response => {
          this.homeBetValue = this.bet.goalshome
          this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss'))
        }, error => {
          this.homeBetValue = this.bet.goalshome
          alert(this.$t('bet.save_bet_error_try_again'))
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.matchdetail_slide {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-left, &-right {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: $colorGreyMid;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    align-items: center;

    img {
      width: 72px;
      height: 72px;
      margin-bottom: 17px;
    }

    svg {
      width: 72px;
      height: 72px;
      margin-bottom: 17px;
      align-self: center;
    }

    span {
      width: 56px;
      height: 56px;
      box-shadow: inset 0 0 0 2px rgba($colorGreyDark, .08);
      border-radius: 32px;
      margin-bottom: 24px;
      margin-top: 6px;
    }
  }

  &-center {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: $colorGreyMid;
  }

  &-goals {
    display: flex;
    height: 72px;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
    font-size: 40px;
    font-weight: 500;
    color: $colorGreyDark;

    &-home {
      width: 24px;
      text-align: right;
      margin-right: 4px;
    }
    &-guest {
      width: 24px;
      text-align: left;
      margin-left: 4px;
    }
  }

  &-date {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @keyframes fadein {
    0%   { margin: 28px -16px -28px; }
    100% { margin: 36px -16px -36px; }
  }

  @keyframes fadeout {
    0%   { margin: 36px -16px -36px; }
    100% { margin: 28px -16px -28px; }
  }

  @keyframes live {
    0%   { opacity: .24; transform: scale(.95); }
    50%  { opacity: 1; transform: scale(1);}
    100% { opacity: .24; transform: scale(.95);}
  }

  &-live {
    display: flex;
    align-items: center;
    flex: 100%;
    background: rgba(245,184,66, .08);
    border-top:1px solid rgba($colorGreyDark, .08);
    padding: 14px 16px;
    margin: 28px -16px -28px;
    animation: fadeout .2s forwards ease-in-out;

    &.gameover {
      background: rgba(51,202,102, .08);
    }

    &.bet_exact_result {
      background: rgba($colorPositive, .08);
    }
    &.bet_goal_diff {
      background: rgba($colorYellow, .08);
    }
    &.bet_result_tendency {
      background: #F5F5F5;
    }
    &.bet_wrong_result {
      background: rgba($colorNegative, .08);
    }

    .swiper-slide-active & {
      animation: fadein .2s forwards ease-in-out;
    }

    &-left {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyMid;
      text-transform: uppercase;
      flex: 1;
      justify-content: flex-start;
      position: relative;

      .bet_exact_result &, .bet_goal_diff &, .bet_result_tendency &, .bet_wrong_result & {
        text-transform: none;
      }
      .bet_exact_result & {
        color: $colorPositive;
      }
      .bet_goal_diff & {
        color: $colorYellow;
      }
      .bet_result_tendency & {
        color: $colorGreyDark;
      }
      .bet_wrong_result & {
        color: $colorNegative;
      }

      span {
        padding-left: 12px;
        opacity: .24;
        transform: scale(.2);
        animation: live 3.4s infinite;

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 6px;
          height: 6px;
          background: $colorNegative;
          border-radius: 3px;
        }
      }
    }

    &-center {
      display: flex;
      justify-content: center;
      font-size: 17px;
      font-weight: 500;
      color: $colorGreyDark;
    }

    &-right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 500;
      color: $colorGreyMid;
    }
  }
}

.bet-input-toto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 21px 4px 36px;

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background: #F1F1F5;
    color: $colorGreyDark;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Rubik';
    padding: 0;
    border-radius: 3px;
    margin: 0 4px;

    @media screen and (max-width: 360px) {
      margin: 2px;
      width: 28px;
      height: 28px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &_selected {
      background: var(--color-primary);
      color: $colorWhite;
    }

    &_win {
      background: $colorPositive;
      color: $colorWhite;
    }

    &_lose {
      background: $colorNegative;
      color: $colorWhite;
    }
  }
}
</style>
