<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M20,0 C22.209139,0 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 0,22.209139 0,20 L0,4 C0,1.790861 1.790861,0 4,0 L20,0 Z M2,8 L2,20 C2,21.0543618 2.81587779,21.9181651 3.85073766,21.9945143 L4,22 L20,22 C21.0543618,22 21.9181651,21.1841222 21.9945143,20.1492623 L22,20 L22,8 L2,8 Z M12,15.5 C12.8284271,15.5 13.5,16.1715729 13.5,17 C13.5,17.8284271 12.8284271,18.5 12,18.5 C11.1715729,18.5 10.5,17.8284271 10.5,17 C10.5,16.1715729 11.1715729,15.5 12,15.5 Z M17,15.5 C17.8284271,15.5 18.5,16.1715729 18.5,17 C18.5,17.8284271 17.8284271,18.5 17,18.5 C16.1715729,18.5 15.5,17.8284271 15.5,17 C15.5,16.1715729 16.1715729,15.5 17,15.5 Z M7,15.5 C7.82842712,15.5 8.5,16.1715729 8.5,17 C8.5,17.8284271 7.82842712,18.5 7,18.5 C6.17157288,18.5 5.5,17.8284271 5.5,17 C5.5,16.1715729 6.17157288,15.5 7,15.5 Z M12,10.5 C12.8284271,10.5 13.5,11.1715729 13.5,12 C13.5,12.8284271 12.8284271,13.5 12,13.5 C11.1715729,13.5 10.5,12.8284271 10.5,12 C10.5,11.1715729 11.1715729,10.5 12,10.5 Z M7,10.5 C7.82842712,10.5 8.5,11.1715729 8.5,12 C8.5,12.8284271 7.82842712,13.5 7,13.5 C6.17157288,13.5 5.5,12.8284271 5.5,12 C5.5,11.1715729 6.17157288,10.5 7,10.5 Z M17,10.5 C17.8284271,10.5 18.5,11.1715729 18.5,12 C18.5,12.8284271 17.8284271,13.5 17,13.5 C16.1715729,13.5 15.5,12.8284271 15.5,12 C15.5,11.1715729 16.1715729,10.5 17,10.5 Z M20,2 L4,2 C2.9456382,2 2.08183488,2.81587779 2.00548574,3.85073766 L2,4 L2,6 L22,6 L22,4 C22,2.9456382 21.1841222,2.08183488 20.1492623,2.00548574 L20,2 Z" fill-rule="nonzero"></path>
    </svg>
</template>

<script>
  export default {
    name: 'CalendarIcon'
  }
</script>

<style lang="scss" scoped>
	svg {
		fill: #44AD34;
	}
</style>
