<template>
  <div id="betgame_menu_content" class="betgame_menu">
    <div class="menu_header">
      <div @click="$modal.hide('betgame-menu')" class="burger_menu">
        <span></span>
        <span></span>
      </div>
      <span class="menu_header-title">{{ $t('menu.title') }}</span>
    </div>
    <div class="betgame_menu_content">
      <router-link to="/dashboard" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_home.svg">
          <span>{{ $t('menu.dashboard') }}</span>
        </div>
      </router-link>
      <router-link to="/bet" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_tippabgabe.svg">
          <span>{{ $t('menu.bet') }}</span>
        </div>
      </router-link>
      <router-link to="/rankingsbymatchday" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_tippuebersicht.svg">
          <span>{{ $t('menu.matchday_ranking') }}</span>
        </div>
      </router-link>
      <router-link to="/rankings" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_gesamtwertung.svg">
          <span>{{ $t('menu.total_ranking') }}</span>
        </div>
      </router-link>
      <!--
      <router-link to="/profile" class="betgame_menu_item">
          <div @click="$modal.hide('betgame-menu')">
              <img src="../assets/icons/ic_88_nav_profile.svg">
              <span>{{ $t('menu.profile') }}</span>
          </div>
      </router-link>
      -->
      <router-link v-if="betgame && betgame.competition && betgame.competition.type === 'LEAGUE'" to="/table" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_tabelle.svg">
          <span>{{ $t('menu.league_table') }}</span>
        </div>
      </router-link>
      <router-link v-else-if="betgame && betgame.competition && betgame.competition.type === 'TOURNAMENT'" to="/tournament" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_tabelle.svg">
          <span>{{ $t('menu.tournament_groups_schedule') }}</span>
        </div>
      </router-link>
      <router-link to="/rules" class="betgame_menu_item full-width">
        <div @click="$modal.hide('betgame-menu')">
          <img src="../assets/icons/ic_24_nav_spielregeln.svg">
          <span>{{ $t('menu.rules') }}</span>
        </div>
      </router-link>

      <div @click="$modal.hide('betgame-menu')" class="powered-by-teamtip">
          <a href="https://teamtip.net/tipprunde-erstellen?src=spielerplus" target="_blank">
          <button><img class="button-icon button-icon--left" src="../assets/icons/ic_12_plus_white.svg">{{ $t('dashboard.button_start_own_betgame') }}</button>
          </a>
          powered by TEAMTIP
      </div>
      <!--
      <button type="button" class="logoutButton" @click="logout">
          Logout
      </button>
      -->
    </div>
  </div>
  <!--
      <ul>
          <li><a href="/dashboard" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'dashboard' })">Dashboard</a></li>
          <li><a href="/bet" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'bet' })">Tippabgabe</a></li>
          <li><a href="/table" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'table' })">Tabelle</a></li>
          <li><a href="/schedule" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'schedule' })">Spelplan</a></li>
          <li><a href="/rankings" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'rankings' })">Wertung</a></li>
          <li><a href="/rankingsbymatchday" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'rankingsbymatchday' })">Spieltagswertung</a></li>
          <li><a href="/profile" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'profile' })">Profil</a></li>
          <li><a href="/rules" @click="this.$modal.hide('betgame-menu'); this.$router.push({ name: 'rules' })">Spielregeln</a></li>
          <li><a href="/" @click="this.$store.dispatch('logout'); this.$modal.hide('betgame-menu'); this.$router.push({ name: '/' })">Logout</a></li>
      </ul>
      -->
</template>

<script>
export default {
  name: 'Menu',
  computed: {
    betgame() {
      return this.$store.state.betgame
    }
  }
}
</script>

<style lang="scss" scoped>

.burger_menu {
  width: 64px;
  height: 64px;
  position: relative;
  cursor: pointer;

  span {
    width: 16px;
    height: 2px;
    position: absolute;
    left: 16px;
    top: 31px;
    background: $colorGreyDark;

    &:first-child {
      top: 31px;
      transform: rotate(45deg);
    }

    &:last-child {
      top: 31px;
      transform: rotate(-45deg);
    }
  }
}

.menu_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 64px;
  align-items: center;
  background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

  &:after {
    content:"D";
    margin: 1px auto 1px 1px;
    visibility: hidden;
    width: 64px;
  }

  &-title {
    font-size: 17px;
    color: $colorGreyDark;
    text-align: center;
    justify-content: center;
    display: flex;
    flex: 1;
  }
}

#betgame_menu_content {
  width: 100%;

  router-link {
    width: 100%;
  }
}

.betgame_menu {

  &_content {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 8px;
    max-width: 528px;
    margin: 0px auto;

    @media screen and (max-width: 320px) {
      padding: 0 10px;
    }
  }

  &_item {
    display: flex;
    flex: calc(50% - 24px);
    box-shadow: inset 0 0 0 2px rgba($colorGreyLight, .08);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    color: $colorGreyDark;
    text-decoration: none;
    justify-content: center;
    transition: .2s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 8px;

    @media screen and (max-width: 320px) {
      flex: calc(50% - 48px);
      margin: 6px;
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 8px 16px 20px;
      width: 100%;

      @media screen and (max-width: 320px) {
        padding: 0 16px 16px;
      }
    }

    img {
      margin: 0 0 -1px 0;
    }

    &:hover {
      color: $colorPrimary;
      box-shadow: inset 0 0 0 2px rgba($colorPrimary, 1), 0 8px 12px 0 rgba($colorPrimary,.08);
    }

    &.full-width {
      flex: 100%;

      div {
        flex-direction: row;
        padding: 12px 16px;
        width: 100%;
      }

      span {
        margin: 0px auto;
        padding-right: 32px;
      }

      img {
        margin: 0 12px 0 0;
      }
    }
  }
}
</style>
