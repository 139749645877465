<template>
  <div class="rankings" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">

    <keep-alive>
      <NavigationBar id="rankingsNavigationBarId" ref="rankingsNavigationBarRef">
        <template v-slot:center>
          <div class="navigation_title--headline">
            {{ $t('menu.total_ranking') }}
          </div>
          <div v-if="finishedMatches && matchCount && !matchRunning" class="navigation_title--subline">
            {{ $t('after_x_of_y_matches', { finished: finishedMatches, total: matchCount }) }}
          </div>
          <div v-else-if="matchRunning" class="navigation_title--subline">
            <span class="match_result_live">Live</span>
          </div>
        </template>
        <template v-slot:right>
          <img v-if="hasSeasonStarted() && !isSpecialCase" v-on:click="toggleSearch" class="navigation_icon" src="../assets/icons/ic_24_search.svg">
        </template>
      </NavigationBar>
    </keep-alive>
    
    <RankingsTableScrolling v-if="hasSeasonStarted()" :searchActive="searchActive"></RankingsTableScrolling>
    <BetgameUsersTable v-else-if="(!hasSeasonStarted()) && betgameUsers.length > 0" :betgameUsers="betgameUsers" :searchActive="searchActive"></BetgameUsersTable>
    
  </div>
</template>

<script>
  //import { mapState } from 'vuex'
  import RankingsTableScrolling from '../components/RankingsTableScrolling'
  import NavigationBar from './../components/NavigationBar'
  import BetgameUsersTable from '@/components/BetgameUsersTable'
  export default {
    name: 'rankings',
    components: { RankingsTableScrolling, NavigationBar, BetgameUsersTable },
    data () {
      return {
        searchActive: false,
      }
    },
    created() {
      //if (this.$store.state.rankings == null || this.$store.state.rankings.length == 0) {
      //  this.$store.dispatch('fetchRanking', [this.$store.state.betgameId, this.$store.state.leagueId])
      //}
    },
    computed: {
      isSpecialCase() {
        return this.$store.state.betgame && this.$store.state.betgame.competition &&
            this.$store.state.betgame.competition.id === 19 && new Date() < new Date(2020, 7, 7)
      },
      betgameUsers () {
        if((!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0)
            && !this.hasSeasonStarted() && this.$store.state.betgame) {
          this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgame.id)
        }
        return this.$store.state.betgameUsers
      },
      matchRunning() {
        return this.$store.getters.matchWithLiveGoals
      },
      matchCount() {
        if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 107) {
          return 51
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 139) {
          return 64
        } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110) {
          return 64
        }
        return this.$store.state.matches.length
      },
      finishedMatches() {
        return this.$store.getters.finishedMatches
      }
    },
    methods: {
      toggleSearch() {
        this.searchActive = !this.searchActive
      },
      openMenu () {
        this.$modal.show('betgame-menu')
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul {
    list-style: none;
  }
  .col_user {
    text-align: left;
    overflow: hidden;
    width: 50%;
  }
  .col_value {
    text-align: right;
    overflow: hidden;
  }
  .col_points {
    margin-right: 10px;
  }
  .match_result_live {
    color: #44AD34;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    padding-right: 12px;
    font-size: 14px;
    opacity: .24;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-animation: live-data-v-43a5ba3a 3.4s infinite;
    animation: live-data-v-43a5ba3a 3.4s infinite;
  
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 5px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: #44AD34;
    }
  }
</style>
