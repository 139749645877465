<template>
    <table id="bg_users_table" class="table">
        <thead>
            <th></th>
            <th class="col_user"></th>
            <th class="col_value">Punkte</th>
            <th class="col_value"><img src="../assets/icons/ic_16_star.svg"></th>
        </thead>
        <tbody></tbody>
        <tr
                v-for="(user, index) in betgameUsers"
                :key="user.fk_user"
                v-bind:class="{ current_user: isCurrentUser(user.fk_user) }"
        >
            <td></td>
            <td class="col_user">{{ user.nickname }}</td>
            <td class="col_value">0</td>
            <td class="col_value">0</td>
        </tr>
        <tfoot></tfoot>
    </table>
</template>

<script>
  export default {
    name: 'BetgameUsersTable',
    props: {
      betgameUsers: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
    },
    methods: {
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            &:first-child {
                //max-width: 4px;
            }

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
    }

    .col_value {
        text-align: right;
        overflow: hidden;

        &:last-child {
            padding-left: 16px;
            max-width: 0;
            text-align: center;
        }
    }
    .col_user {
        text-align: left;
        overflow: hidden;
    }

    .current_user {
        background-color: #F2F2F2;
    }

</style>
