<template>
  <div class="start-page">

    <NavigationBar id="navigationBar">
      <template v-slot:center>
        Tipprunde
      </template>
    </NavigationBar>

    <div class="start-container">
      <h1 style="color: #44AD34">Starte...</h1>
      <div style="margin-top: 20px;">
        <hollow-dots-spinner
            :animation-duration="1000"
            :dot-size="15"
            :dots-num="5"
            color="#44AD34"
        />
      </div>
    </div>

  </div>
</template>

<script>

import NavigationBar from '../components/NavigationBar'
import { HollowDotsSpinner } from 'epic-spinners'
import { bootstrap } from 'vue-gtag'
import jwt_decode from 'jwt-decode'

export default {
  name: 'StartPage',
  components: { NavigationBar, HollowDotsSpinner },
  mounted: function () {
    this.$store.dispatch('sendDebug', { name: 'startpage_mounted' })
    if(this.getBGValue('spmau')) {
      this.$store.dispatch('setApiUrl', this.getBGValue('spmau'))
    }
    if(this.getBGValue('spmbgid')) {
      const id = this.getBGValue('spmbgid')
      this.$store.dispatch('setBetgameId', id)
      this.$store.dispatch('fetchBetgame', id)
      //this.$store.dispatch('fetchCurrentSeason', id)
      this.$store.dispatch('fetchBetgameLeague', id)
    }
    if(this.getBGValue('userSource')) {
      this.$store.dispatch('setUserSource', this.getBGValue('userSource'))
    }
    if(this.getBGValue('sp_ads')) {
      this.$store.dispatch('setSPAds', this.getBGValue('sp_ads'))
    }
    if(this.getBGValue('sp_app')) {
      this.$store.dispatch('setSPApp', this.getBGValue('sp_app'))
    }
    if(this.getBGValue('sp_lang')) {
      this.$store.dispatch('setLanguage', this.getBGValue('sp_lang'))
    }
    

    var token = ''
    var tokenValid = false
    const lastRoute = window.localStorage.getItem('spmlr')

    //this.$router.push({ name: 'start' })
    let that = this
    window.addEventListener('message', function (event) {
      //if (event.origin != 'https://dev-tippspiel.deinsportplatz.de') {
      //  return
      //}
      if (event.data.name === 'betgame_login') {

        const email = event.data.email
        const pass = event.data.password
        var oldEmail = ''
        const newid = window.localStorage.getItem('spmbgid')
        const oldid = window.localStorage.getItem('spmbgid0')

        if(window.localStorage.getItem('sp_id')) {
          oldEmail = window.localStorage.getItem('sp_id')
        }
        if(window.localStorage.getItem('token')) {
          token = window.localStorage.getItem('token')
        }
        if(token !== undefined) {
          tokenValid = that.isValidToken(token, email)
        }
        var switchBetgame = false
        if(newid && oldid && parseInt(newid) > 0 && parseInt(newid) !== parseInt(oldid)) {
          switchBetgame = true
          window.localStorage.setItem('spmbgid0', newid)
        }

        if(tokenValid && email !== undefined && email.length > 0 && email === oldEmail && switchBetgame === false) {
          that.$store.dispatch('sendDebug', { name: 'startpage_mounted_loginLocal' })
          //if(that.$router.path !== 'start') {
          //  that.$router.push({ name: 'start' })
          //}
          that.$store.dispatch('loginLocal', token).then(response => {
            bootstrap().then(gtag => {
              that.$gtag.set({
                'user_id': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'clientId': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'cookie_flags': 'SameSite=None;Secure',
                'anonymize_ip': true,
                //'client_storage': 'none',
                'dimension1': that.$store.state.betgame && that.$store.state.betgame.name ? that.$store.state.betgame.name : 'betgame not set',
                'dimension2': that.$store.state.user && that.$store.state.user.source ? that.$store.state.user.source : 'user source not set',
                'dimension3': that.$store.state.betgame && that.$store.state.betgame.id ? that.$store.state.betgame.id : 'betgame id not set'
              })
              that.$gtag.pageview({
                page_title: that.$route.name,
                page_path: that.$route.path,
                page_location: window.location.href
              })
            })
            if (lastRoute !== undefined && lastRoute !== null && lastRoute.length >= 0  && lastRoute !== 'undefined') {
              that.$router.push({ name: lastRoute })
            } else {
              that.$router.push({ name: 'dashboard' })
            }
          })
        } else {
          that.$store.dispatch('sendDebug', { name: 'startpage_mounted_login' })
          if(that.$router.path !== 'start') {
            that.$router.push({ name: 'start' })
          }
          window.localStorage.setItem('sp_id', event.data.email)
          that.$store.dispatch('login', {
            email: event.data.email,
            pass: event.data.password
          }).then(() => {
            bootstrap().then(gtag => {
              that.$gtag.set({
                'user_id': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'clientId': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'anonymize_ip': true,
                //'client_storage': 'none',
                'dimension1': that.$store.state.betgame && that.$store.state.betgame.name ? that.$store.state.betgame.name : 'betgame not set',
                'dimension2': that.$store.state.user && that.$store.state.user.source ? that.$store.state.user.source : 'user source not set',
                'dimension3': that.$store.state.betgame && that.$store.state.betgame.id ? that.$store.state.betgame.id : 'betgame id not set'
              })
              that.$gtag.pageview({
                page_title: that.$route.name,
                page_path: that.$route.path,
                page_location: window.location.href
              })
            })
            that.$router.push({ name: 'dashboard' })
          }, error => {
            that.$router.push({ name: 'error' })
          })
        }
      }
    })
    window.parent.postMessage({'func': 'betGameLoginListener', 'message': 'get_login'}, '*')

    /*
    this.setBGValue('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTMsIm5pY2tuYW1lIjoibGhzcG0iLCJlbWFpbCI6ImxoQHNwbS5kZSIsInN0YXR1cyI6ImFjdGl2ZSIsInJvbGUiOiJiZXRnYW1lX3VzZXIiLCJzb3VyY2UiOm51bGwsInJlZ2lzdHJhdGlvbl9kYXRlIjoiMjAyMC0wOC0yNVQyMzowNDo0NSIsInJvbGUiOiJiZXRnYW1lX3VzZXIiLCJleHAiOjE1OTkyNjEwOTR9.oEbSDxc1QBsMNe4MKewVq4BYN3ZXMaoS0kac6kTFqQQ')
    
    if(this.getBGValue('token')) {
      const token = this.getBGValue('token')
      if(token !== undefined) {
        this.$store.dispatch('loginLocal', this.getBGValue('token')).then(response => {
          bootstrap().then(gtag => {
            this.$gtag.set({
              'anonymize_ip': true,
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
            })
            this.$gtag.pageview({
              page_title: this.$route.name,
              page_path: this.$route.path,
              page_location: window.location.href
            })
          })
          const r = this.$cookies.get('lastRoute')
          if (r !== undefined && r !== null && r.length >= 0  && r !== 'undefined') {
            this.$router.push({ name: r })
          } else {
            const spmlr = window.localStorage.getItem('spmlr')
            const cur = this.$router.currentRoute ? this.$router.currentRoute.name : ''
            if (spmlr !== undefined && spmlr !== null && spmlr.length >= 0  && spmlr !== 'undefined') {
              if(spmlr !== cur) {
                this.$router.push({ name: spmlr })
              }
            } else {
              this.$router.push({ name: 'dashboard' })
            }
          }
        })
      } else {
        const r = this.$cookies.get('lastRoute')
        if(r !== undefined && r !== null && r.length >= 0 && r !== 'undefined') {
          this.$router.push({ name: r }).catch(err => {})
        } else {
          this.$router.push({ name: 'dashboard' }).catch(err => {})
        }
      }
    }
    */
  },
  methods: {
    isValidToken(token, email) {
      var result = false
      try {
        const payload = jwt_decode(token)
        const expDate = payload.exp * 1000
        if (expDate > Date.now() && email === payload.email) {
          result = true
        }
      } catch (e) {
        result = false
      }
      return result
    }
  }
}

</script>

<style lang="scss" scoped>
.start {

  &-page {
    display: flex;
  }

  &-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
    width: 100%;
    align-items: center;
    margin-top: 80px;
    text-align: center;

    button {
      margin-top: 27px;
    }
  }
}
</style>
