<template>
  <div>
    <table id="bg_rankings_table" class="table">
        <thead>
            <th v-if="matchRunning" style="color: red"><span class="match_result_live">Live</span></th>
            <th v-else></th>
            <th class="col_user"></th>
            <th class="col_value">Punkte</th>
            <th v-if="!isBattle() && !isTournament()" class="col_value"><img src="../assets/icons/ic_16_star.svg"></th>
        </thead>
        <tbody></tbody>
        <tr
                v-for="(ranking, index) in rankings"
                :key="ranking.fk_user"
                v-bind:class="{ current_user: isCurrentUser(ranking.fk_user) }"
        >
            <td v-if="!equalPoints(index)">{{ ranking.position }}.</td>
            <td v-else></td>          
            <td class="col_user">{{ ranking.user_name }}</td>
            <td class="col_value">{{ ranking.points_total }}</td>
            <td v-if="!isBattle() && !isTournament()" class="col_value">{{ ranking.matchday_wins }}</td>
        </tr>
        <tfoot></tfoot>
    </table>
    
    <div style="width: 100%; text-align: center; align-items: center;">
      <router-link to="/rankings">
        <button style="max-width: 320px;">Zur Gesamtwertung</button>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RankingsTable',
    props: {
      rankings: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      matchRunning() {
        return this.$store.getters.matchWithLiveGoals
      },
    },
    methods: {
      equalPoints(index) {
        if(index === 0 || !this.rankings || this.rankings.length <= index) {
          return false
        }
        const r1 = this.rankings[index]
        const r2 = this.rankings[index - 1]
        if(r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins) {
          return true
        }
        return false
      },      
      getMatchdayWins (user) {
        return this.$store.getters.getMatchdayWins(user)
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            &:first-child {
                //max-width: 4px;
            }

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
    }

    .col_value {
        text-align: right;
        overflow: hidden;

        &:last-child {
            padding-left: 16px;
            max-width: 0;
            text-align: center;
        }
    }
    .col_user {
        text-align: left;
        overflow: hidden;
        padding-left: 10px;
    }

    .current_user {
        background-color: #F2F2F2;
    }
    
    .match_result_live {
        color: #44AD34;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        padding-right: 12px;
        font-size: 14px;
        opacity: .24;
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
        -webkit-animation: live-data-v-43a5ba3a 3.4s infinite;
        animation: live-data-v-43a5ba3a 3.4s infinite;
    
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 5px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: #44AD34;
        }
    }
</style>
