<template>
	<div class="error-page">
		
		<!-- NavigationBar nur anzeigen, wenn der User eingeloggt ist -->
		<NavigationBar>
	      <template v-slot:center>
	        Error
	      </template>
	    </NavigationBar>
        
        <div class="error-container">
        	<h1>Ooooops...</h1>
        	<p>Die von dir aufgerufene Seite existiert nicht mehr bzw. hat nie existiert...</p>
            <router-link v-if="this.$store.state.user" to="/dashboard">
        		<button>Zum Dashboard</button>
        	</router-link>
        </div>
		
	</div>
</template>

<script>

	import NavigationBar from '../components/NavigationBar'

	export default {
    	name: 'ErrorPage',
    	components: { NavigationBar },
		mounted() {
			//window.parent.postMessage({func: 'betGameListener', message: '401'}, '*')
		},
	    methods: {
	      	openMenu () {
	        	this.$modal.show('betgame-menu')
	      	}
	    }
  	}

</script>

<style lang="scss" scoped>
	.error {

		&-page {
			display: flex;
		}

		&-container {
			display: flex;
			flex-direction: column;
			min-height: calc(100vh - 80px);
			width: 100%;
			align-items: center;
			justify-content: center;
			text-align: center;

			button {
				margin-top: 27px;
			}
		}
	}
</style>
