/* eslint-disable */
<template>
  <div id="app">
    <!-- <NavigationBar v-bind:class="{ appnav: loggedIn }" v-if="loggedIn"></NavigationBar> -->
    <!-- <app-nav v-bind:class="{ appnav: loggedIn }" id="app-nav" v-if="loggedIn" /> -->

    <!--
    <NavigationBar v-if="loggedIn">
      <template v-slot:left>
        // menu, profile, macthdayselect, text (center, 2-zeilen, back)
        <button v-if="" v-on:click="openMenu">Menu</button>
        <button v-else-if="" v-on:click="openMenu">Menu</button>
      </template>
      <template v-slot:center>
        Tippabgabe<br>{{ matchday }}. Spieltag ({{ matchdayCount }})
      </template>
      <template v-slot:right>
        <button v-on:click="openMatchdaySelect">Select</button>
      </template>
    </NavigationBar>
    -->

    <router-view v-bind:class="{ pageoffset: loggedIn }" class="page" />
    <modal name="betgame-menu" id="betgame-menu" :scrollable="true" width="100%" height="auto">
      <Menu></Menu>
    </modal>

    <div data-iframe-height></div>
    
  </div>
</template>

<script>
import AppNav from './components/AppNav'
import { authComputed } from '@/vuex/helpers.js'
import NavigationBar from './components/NavigationBar'
import Menu from './components/Menu'
import jwt_decode from 'jwt-decode'
import { bootstrap } from 'vue-gtag'
import Numeral from 'numeral'
import moment from 'moment'

export default {
  components: { AppNav, NavigationBar, Menu },
  computed: {
    ...authComputed
  },
  beforeCreate: function() {
    this.$store.dispatch('sendDebug', { name: 'app_beforeCreate' })
    window.localStorage.setItem('spmau', 'https://spielerplus.teamtip.net')
    window.localStorage.setItem('userSource', 'spielerplus')
    const id = this.$route.query.id
    const oldid = window.localStorage.getItem('spmbgid')
    if(id !== undefined) {
      window.localStorage.setItem('spmbgid', id)
      if(oldid != undefined && parseInt(oldid) > 0 && parseInt(id) !== parseInt(oldid)) {
        window.localStorage.setItem('spmbgid0', oldid)
      } else {
        window.localStorage.setItem('spmbgid0', id)
      } 
    } else {
      window.localStorage.removeItem('spmbgid')
    }
    const ads = this.$route.query.ads
    if(ads !== undefined) {
      window.localStorage.setItem('sp_ads', ads)
    } else {
      window.localStorage.removeItem('sp_ads')
    }
    const app = this.$route.query.app
    if(app !== undefined) {
      window.localStorage.setItem('sp_app', app)
    } else {
      window.localStorage.removeItem('sp_app')
    }
    const lang = this.$route.query.lang
    if(lang !== undefined) {
      if(lang.toLowerCase().startsWith('de')) {
        window.localStorage.setItem('sp_lang', 'de') 
      } else {
        window.localStorage.setItem('sp_lang', 'en')
      }
    } else {
      window.localStorage.removeItem('sp_lang')
    }
    /*
    const api = this.$route.query.api
    if(api !== undefined) {
      if(api.startsWith('http')) {
        window.sessionStorage.setItem('apiUrl', api)
      } else {
        window.sessionStorage.setItem('apiUrl', 'https://' + api)
      }
    } else {
      const domain = document.domain
      if(domain !== undefined && domain.includes('tippspiel') && domain.includes('deinsportplatz.de')) {
        window.sessionStorage.setItem('apiUrl', 'https://' + domain)
      }
    }
    const app = this.$route.query.app
    if(app !== undefined) {
      window.sessionStorage.setItem('sp_app', app)
    } else {
      window.sessionStorage.removeItem('sp_app')
    }
    const ads = this.$route.query.ads
    if(ads !== undefined) {
      window.sessionStorage.setItem('sp_ads', ads)
    } else {
      window.sessionStorage.removeItem('sp_ads')
    }
    const id = this.$route.query.id
    if(id !== undefined) {
      window.sessionStorage.setItem('betgameId', id)
    }
    const src = this.$route.query.src
    if(src !== undefined) {
      window.sessionStorage.setItem('userSource', src)
    }
    */
  },
  created () {
    this.$store.dispatch('sendDebug', { name: 'app_created' })
    this.$log.debug('app created ' + this.$route.params.id + ' --- ' + this.$route.name + ' --- ' + this.$route.query.code + ' --- ' + this.$route.query.email)
    if(this.getBGValue('spmau')) {
      this.$store.dispatch('setApiUrl', this.getBGValue('spmau'))
    }
    if(this.$store.getters.loggedIn && this.$store.state.user.id) {
      const ltr = new Date(this.getBGValue('spmltr'))
      this.setBGValue('spmltrc', new Date())
      if(ltr === undefined || ltr.getDate() !== new Date().getDate() || ltr.getFullYear() !== new Date().getFullYear() || ltr.getMonth() !== new Date().getMonth()) {
        if(this.$store.state.user && this.$store.state.user.status === 'active') {
          const data = {
            'id': '' + this.$store.state.user.id
          }
          this.$store.dispatch('refreshToken', data).then(response => {
          })
        }
      }
    }
    this.$log.debug("this.getBGValue('spmbgid'): " + this.getBGValue('spmbgid'))
    if(this.getBGValue('spmbgid') === 'null' || this.getBGValue('spmbgid') === undefined || this.getBGValue('spmbgid') === 'undefined') {
      this.clearBGValue('spmbgid')
      if(navigator.userAgent.indexOf('TEAMTIP') > 0) {
        this.clearBGValue('betgameId')
      }
    }
    if(this.$store.state.language) {
      Numeral.locale(this.$store.state.language)
      moment.locale(this.$store.state.language)
    }
    if(this.$store.state.language && this.$i18n.locale !== this.$store.state.language) {
      this.$log.debug('app created set i18n.locale ' + this.$store.state.language)
      this.$i18n.locale = this.$store.state.language
    } else {
      this.$log.debug('app created use i18n.locale ' + this.$i18n.locale)
    }
    if(this.$store.state.geoData === null) {
      this.$store.dispatch('getGeoData', []).then(response => {
      }, error => {
      })
    }
    if(this.getBGValue('spmbgid')) {
      const id = this.getBGValue('spmbgid')
      this.$log.debug("app created bg.id: " + id)
      if(parseInt(id) > 0) {
        this.$store.dispatch('setBetgameId', id)
        //this.$store.dispatch('fetchBetgameUsers', id)
        if (!this.$store.state.currentSeason) {
          this.$store.dispatch('fetchCurrentSeason', id)
        }
        if (this.$store.state.token && this.$store.state.token.length > 0 &&
            (!this.$store.state.leagueId || this.$store.state.leagueId < 1 || !this.$store.state.teams || this.$store.state.teams.length === 0)) {
          this.$store.dispatch('fetchBetgameLeague', id).then((leagueid) => {
            this.$store.dispatch('fetchTeams', leagueid).then(() => {

            })
          })
        }
      }
    }
    if(this.getBGValue('userSource')) {
      this.$store.dispatch('setUserSource', this.getBGValue('userSource'))
    }
  },
  mounted: function () {
    this.$store.dispatch('sendDebug', { name: 'app_mounted' })
    if(this.getBGValue('spmau')) {
      this.$store.dispatch('setApiUrl', this.getBGValue('spmau'))
    }
    if(this.getBGValue('spmbgid')) {
      const id = this.getBGValue('spmbgid')
      this.$store.dispatch('setBetgameId', id)
      this.$store.dispatch('fetchBetgame', id)
      //this.$store.dispatch('fetchCurrentSeason', id)
      this.$store.dispatch('fetchBetgameLeague', id)
    }
    if(this.getBGValue('userSource')) {
      this.$store.dispatch('setUserSource', this.getBGValue('userSource'))
    }
    if(this.getBGValue('sp_ads')) {
      this.$store.dispatch('setSPAds', this.getBGValue('sp_ads'))
    }
    if(this.getBGValue('sp_app')) {
      this.$store.dispatch('setSPApp', this.getBGValue('sp_app'))
    }
    if(this.getBGValue('sp_lang')) {
      this.$store.dispatch('setLanguage', this.getBGValue('sp_lang'))
    }
    
    /*
    var token = ''
    var tokenValid = false
    const lastRoute = window.localStorage.getItem('spmlr')

    //this.$router.push({ name: 'start' })
    let that = this
    window.addEventListener('message', function (event) {
      //if (event.origin != 'https://dev-tippspiel.deinsportplatz.de') {
      //  return
      //}
      if (event.data.name === 'betgame_login') {

        const email = event.data.email
        const pass = event.data.password
        var oldEmail = ''
        const newid = window.localStorage.getItem('spmbgid')
        const oldid = window.localStorage.getItem('spmbgid0')
        
        if(window.localStorage.getItem('sp_id')) {
          oldEmail = window.localStorage.getItem('sp_id')
        }
        if(window.localStorage.getItem('token')) {
          token = window.localStorage.getItem('token')
        }
        if(token !== undefined) {
          tokenValid = that.isValidToken(token, email)
        }
        var switchBetgame = false
        if(newid && oldid && parseInt(newid) > 0 && parseInt(newid) !== parseInt(oldid)) {
          switchBetgame = true
          window.localStorage.setItem('spmbgid0', newid)
        }
        
        if(tokenValid && email !== undefined && email.length > 0 && email === oldEmail && switchBetgame === false) {
          //if(that.$router.path !== 'start') {
          //  that.$router.push({ name: 'start' })
          //}
          that.$store.dispatch('loginLocal', token).then(response => {
            bootstrap().then(gtag => {
              that.$gtag.set({
                'user_id': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'clientId': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'cookie_flags': 'SameSite=None;Secure',
                'anonymize_ip': true,
                //'client_storage': 'none',
                'dimension1': that.$store.state.betgame && that.$store.state.betgame.name ? that.$store.state.betgame.name : 'betgame not set',
                'dimension2': that.$store.state.user && that.$store.state.user.source ? that.$store.state.user.source : 'user source not set',
                'dimension3': that.$store.state.betgame && that.$store.state.betgame.id ? that.$store.state.betgame.id : 'betgame id not set'
              })
              that.$gtag.pageview({
                page_title: that.$route.name,
                page_path: that.$route.path,
                page_location: window.location.href
              })
            })
            if (lastRoute !== undefined && lastRoute !== null && lastRoute.length >= 0  && lastRoute !== 'undefined') {
              that.$router.push({ name: lastRoute })
            } else {
              that.$router.push({ name: 'dashboard' })
            }
          })
        } else {
          if(that.$router.path !== 'start') {
            that.$router.push({ name: 'start' })
          }
          window.localStorage.setItem('sp_id', event.data.email)
          that.$store.dispatch('login', {
            email: event.data.email,
            pass: event.data.password
          }).then(() => {
            bootstrap().then(gtag => {
              that.$gtag.set({
                'user_id': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'clientId': that.$store.state.user ? '' + that.$store.state.user.id : '',
                'anonymize_ip': true,
                //'client_storage': 'none',
                'dimension1': that.$store.state.betgame && that.$store.state.betgame.name ? that.$store.state.betgame.name : 'betgame not set',
                'dimension2': that.$store.state.user && that.$store.state.user.source ? that.$store.state.user.source : 'user source not set',
                'dimension3': that.$store.state.betgame && that.$store.state.betgame.id ? that.$store.state.betgame.id : 'betgame id not set'
              })
              that.$gtag.pageview({
                page_title: that.$route.name,
                page_path: that.$route.path,
                page_location: window.location.href
              })
            })
            that.$router.push({ name: 'dashboard' })
          }, error => {
            that.$router.push({ name: 'error' })
          })
        }
      }
    })
    window.parent.postMessage({'func': 'betGameLoginListener', 'message': 'get_login'}, '*')
    */
    /*
    this.setBGValue('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTMsIm5pY2tuYW1lIjoibGhzcG0iLCJlbWFpbCI6ImxoQHNwbS5kZSIsInN0YXR1cyI6ImFjdGl2ZSIsInJvbGUiOiJiZXRnYW1lX3VzZXIiLCJzb3VyY2UiOm51bGwsInJlZ2lzdHJhdGlvbl9kYXRlIjoiMjAyMC0wOC0yNVQyMzowNDo0NSIsInJvbGUiOiJiZXRnYW1lX3VzZXIiLCJleHAiOjE1OTkyNjEwOTR9.oEbSDxc1QBsMNe4MKewVq4BYN3ZXMaoS0kac6kTFqQQ')
    
    if(this.getBGValue('token')) {
      const token = this.getBGValue('token')
      if(token !== undefined) {
        this.$store.dispatch('loginLocal', this.getBGValue('token')).then(response => {
          bootstrap().then(gtag => {
            this.$gtag.set({
              'anonymize_ip': true,
              'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
              'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
              'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set'
            })
            this.$gtag.pageview({
              page_title: this.$route.name,
              page_path: this.$route.path,
              page_location: window.location.href
            })
          })
          const r = this.$cookies.get('lastRoute')
          if (r !== undefined && r !== null && r.length >= 0  && r !== 'undefined') {
            this.$router.push({ name: r })
          } else {
            const spmlr = window.localStorage.getItem('spmlr')
            const cur = this.$router.currentRoute ? this.$router.currentRoute.name : ''
            if (spmlr !== undefined && spmlr !== null && spmlr.length >= 0  && spmlr !== 'undefined') {
              if(spmlr !== cur) {
                this.$router.push({ name: spmlr })
              }
            } else {
              this.$router.push({ name: 'dashboard' })
            }
          }
        })
      } else {
        const r = this.$cookies.get('lastRoute')
        if(r !== undefined && r !== null && r.length >= 0 && r !== 'undefined') {
          this.$router.push({ name: r }).catch(err => {})
        } else {
          this.$router.push({ name: 'dashboard' }).catch(err => {})
        }
      }
    }
    */
  },
  methods: {
    isValidToken(token, email) {
      var result = false
      try {
        const payload = jwt_decode(token)
        const expDate = payload.exp * 1000
        if (expDate > Date.now() && email === payload.email) {
          result = true
        }
      } catch (e) {
        result = false
      }
      return result
    }
  },
  watch:{
    $route (to, from){
      // console.log(from.path + " --- " + to.patch + " --- " + this.$router.currentRoute.path)
    }
  }
}
</script>

<style lang="scss">

@import './assets/styles/global.scss';

#app {
}

.appnav {
  background-color: lightgray;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  /* NavigationBar
  width: 100%;
  display: flex;
   */
}

.pageoffset {
  padding-top: 64px !important;
}

</style>
