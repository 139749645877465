<template>
    <div class="table">
        <div class="searchbar" v-bind:class="{ searchbar_active: searchActive }">
            <div class="searchbar-item searchtype-user">
              <input class="search-input" @input="searchInputChanged" type="text" placeholder="User suchen..." ref="searchinput">
            </div>
            <div v-if="false" class="searchbar-item searchtype-location">
              <select class="search-input" @input="searchInputChanged" ref="searchinput">
                <option value="">Alle Landkreise</option>
                <option value="Wandsbek">Wandsbek</option>
                <option value="St.Pauli">St.Pauli</option>
              </select>
            </div>
        </div>

        <thead>
            <!--<th class="col_search" colspan="2">
                <input @input="searchInputChanged" type="text" placeholder="User suchen..." style="width: 100%; max-width: 200px;"></input>
            </th>-->
            <th class="col_pos"></th>
            <th class="col_user"></th>
            <th class="col_value">Punkte</th>
            <th class="col_value"><img src="../assets/icons/ic_16_star.svg"></th>
        </thead>
        <tr
                v-for="(ranking, index) in rankings"
                :key="infiniteId + '_' + ranking.fk_user"
                v-bind:class="{ current_user: isCurrentUser(ranking.fk_user) }"
        >
            <td v-if="!equalPoints(index)" class="col_pos">{{ ranking.position }}.</td>
            <td v-else class="col_pos"></td>          
            <td class="col_user">{{ ranking.user_name }}</td>
            <td class="col_value">{{ ranking.points_total }}</td>
            <td class="col_value">{{ ranking.matchday_wins }}</td>
        </tr>
    
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
            <div slot="spinner"><div class="messgae">Lade...</div></div>
            <div slot="no-more">
                <div class="statistics-empty">
                    Alle Teilnehmer wurden geladen.
                </div>
            </div>
            <div slot="no-results">
                <div class="statistics-empty">
                    Sobald die ersten Spiele beendet sind, findest du hier die Bestenliste.
                </div>
            </div>
        </infinite-loading>
        
    </div>
    
</template>

<script>
  
  import InfiniteLoading from 'vue-infinite-loading';
  import APIService from '@/services/APIService'
  
  export default {
    name: 'RankingsTableScrolling',
    components: {
      InfiniteLoading,
    },
    data() {
      return {
        page: 1,
        rankings: [],
        searchString: '',
        infiniteId: +new Date(),
      };
    },
    props: {
      searchActive: {
        type: Boolean,
        default: () => {
          return false
        }
      }
    },
    methods: {
      equalPoints(index) {
        if(index === 0 || !this.rankings || this.rankings.length <= index) {
          return false
        }
        const r1 = this.rankings[index]
        const r2 = this.rankings[index - 1]
        if(r1.points_total === r2.points_total && r1.matchday_wins === r2.matchday_wins) {
          return true
        }
        return false
      },      
      searchInputChanged({ type, target }) {
        this.searchString = target.value.trim()
        this.page = 1
        this.rankings = []
        this.infiniteId += 1
      },
      infiniteHandler($state) {
  
        APIService.getLiveRankingPage(this.$store.state.betgameId, this.$store.state.leagueId, this.searchString, (this.page - 1) * 500, 500)
        .then(response => {
          if (response.data.length) {
            this.page += 1;
            this.rankings.push(...response.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
        })
        
      },
      isCurrentUser(userId) {
        if(this.$store.state.user) {
          return this.$store.state.user.id === userId
        }
      }
    },
    watch: {
      searchActive: function (newValue) {
        if(newValue === false && this.searchString.trim().length > 1) {
          this.searchString = ''
          this.page = 1
          this.rankings = []
          this.infiniteId += 1
        } else if(newValue === true) {
          this.$refs.searchinput.value = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .message {
        text-align: center;
        color: darkgray;
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .table {

        td, th {
            border-bottom: 1px solid rgba($colorGreyLight, .08);

            thead & {
                font-weight: 400;
                color: $colorGreyLight;
            }
        }
    }

    .col_value {
        text-align: right;
        overflow: hidden;

        &:last-child {
            padding-left: 16px;
            text-align: center;
        }
    }

    .col_user {
        text-align: center;
        overflow: hidden;
        width: 100%;
        max-width: 80px;
    }

    .current_user {
        background-color: #F2F2F2;
    }

    .col_pos {
        text-align: center !important;
        overflow: hidden;
        padding-right: 16px;
    }

</style>
