import axios from 'axios'
import { API_TIMEOUT, DASHBOARD_RANKINGS_SIZE } from '../main'
import store from '../vuex/store'
import moment from 'moment'

//export const API_URL = `https://tippspiel.deinsportplatz.de`

const apiClient = axios.create({
    //baseURL: window.localStorage.getItem('spmau'),
    timeout: API_TIMEOUT,
    withCredentials: true, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

function getAPIURL() {
    return store.state.apiUrl
}

function getToken() {
    return store.state.token
}

export default {
    
    getLiveRankingsByPosition (betgame, competition, position) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'and=(position.gte.' + (position - DASHBOARD_RANKINGS_SIZE) + ',position.lte.' + (position + DASHBOARD_RANKINGS_SIZE) + ')&fk_betgame=eq.' + betgame +
          '&fk_competition=eq.' + competition,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getUserRanking (betgame, competition, user) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_user=eq.' + user + '&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getRanking (betgame, competition) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=100',
                {
                    headers: { Authorization: 'Bearer ' + getToken() }
                }
        )
    },
    
    getLiveRankingByUsers (betgame, competition, users) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&fk_user=in.(' + users + ')',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRanking (betgame, competition, username, currentuser) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=20' + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getRankingPage (betgame, competition, offset, limit) {
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=' + limit + '&offset=' + offset,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getLiveRankingPage (betgame, competition, username, offset, limit) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            userfilter = '&user_name=ilike.*' + username + '*'
        }
        return apiClient.get(getAPIURL() + '/bg_v_ranking?select=position,fk_betgame,fk_competition,fk_user,user_name,points_total,matchday_wins,exact,goaldiff,tendency,no_points,betcount&' +
          'order=position.asc&fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&limit=' + limit + '&offset=' + offset + userfilter,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getMatchdayRanking (betgame, competition, matchday, limit, username, currentuser) {
        var userfilter = ''
        if(username !== undefined && username !== null && username.trim().length > 1) {
            if(currentuser !== undefined && currentuser !== null && currentuser > 0) {
                userfilter = '&or=(user_name.ilike.*' + username + '*,fk_user.eq.' + currentuser + ')'
            } else {
                userfilter = '&user_name=ilike.*' + username + '*'
            }
        }
        return apiClient.get(getAPIURL() + '/bg_v_matchday_ranking?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&matchday=eq.' + matchday + userfilter + '&limit=' + limit + '&order=points_total.desc,exact.desc',
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getMatchdayRankingsByUser (betgame, competition, user) {
        return apiClient.get(getAPIURL() + '/bg_v_matchday_ranking?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_betgame=eq.' + betgame + '&fk_competition=eq.' + competition + '&fk_user=eq.' + user,
          {
              headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
    },
    
    getMatchBets (competition) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        const date = moment().subtract(1, 'days').format('YYYY-MM-DD')
        return apiClient.get(getAPIURL() + '/bg_v_schedule?select=bg_match_bet(*)&fk_competition=eq.' + competition + '&matchdate=gt.' + date, config)
    },
    
    getBets (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        if(userId != null) {
            return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_user=eq.' + userId, config)
        } else {
            return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId, config)
        }
    },
    
    getBetsByUsers (betgameId, userIds) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_user=in.(' + userIds + ')', config)
    },
    
    getBetsByUsersAndMatches (betgameId, userIds, matchIds) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId + '&fk_user=in.(' + userIds + ')' + '&fk_match=in.(' + matchIds + ')', config)
    },
    
    getUserBetgames (userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/user_betgames', { user_id: userId}, config)
    },
    
    getTable (leagueId, seasonId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_v_table?order=position.asc&fk_competition=eq.' + leagueId + '&fk_season=eq.' + seasonId, config)
    },
    
    getBetgameLeague(betgameId) {
        return apiClient.get(getAPIURL() + '/bg_betgame_competition?select=bg_competition(id,name,type)&fk_betgame=eq.' + betgameId)
    },
    
    getBetgame (betgameId) {
        return apiClient.get(getAPIURL() +
          '/bg_betgame?select=*,bg_betgame_fk_user_key(nickname)&id=eq.' + betgameId
        )
    },
    
    getBetgameWithLeague(betgameId) {
        return apiClient.get(getAPIURL() + '/bg_betgame_competition?select=bg_betgame(*,bg_user!bg_betgame_fk_user_key(nickname)),bg_competition(id,name,type)&fk_betgame=eq.' + betgameId)
    },
    
    getBetgameByInvitation (betgameId, invitation) {
        return apiClient.get(getAPIURL() +
          '/bg_betgame?id=eq.' + betgameId + '&invitation_code=eq.' + invitation
        )
    },
    
    getBetgames (betgameIds, user) {
        return apiClient.get(getAPIURL() +
          '/bg_betgame?or=(fk_user.eq.' + user + ',id.in.(' + betgameIds + '))'
        )
    },
    
    getPublicBetgames (foo) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame?is_public=eq.true')
    },
    
    getCurrentSeason () {
        return apiClient.get(getAPIURL() + '/bg_season?is_current=eq.true')
    },
    
    getTeams (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_team', config)
    },
    
    getLeagueTeams (leagueId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_competition_team?select=bg_team(id,name,shortname,logo,short,color,bet_id,kickerid,heimspielid,name_int,shortname_int,short_int)&fk_competition=eq.' + leagueId, config)
    },
    
    getSchedule (leagueId, seasonId) {
        return apiClient.get(getAPIURL() +
          '/bg_v_schedule?fk_competition=eq.' + leagueId + '&fk_season=eq.' + seasonId
        )
    },
    
    getMatchdaySchedule (leagueId, seasonId, matchday) {
        return apiClient.get(getAPIURL() +
          '/bg_v_schedule?fk_competition=eq.' + leagueId + '&fk_season=eq.' + seasonId + '&matchday=eq.' + matchday
        )
    },
    
    isEmailRegistered(email) {
        return apiClient.get(getAPIURL() + '/bg_user?select=id&email=eq.' + email.toLowerCase()
        )
    },
    
    // eslint-disable-next-line
    postLogin (data) {
        return apiClient.post(getAPIURL() + '/rpc/login', data)
    },
    
    postRegistration (nick, email, passwd) {
        return apiClient.post(getAPIURL() + '/rpc/signup', {
            nick: nick,
            email: email.toLowerCase(),
            pass: passwd
        })
    },
    
    postValidateRegistration (data) {
        // eslint-disable-next-line
        return apiClient.post(getAPIURL() + '/rpc/validate_registration', data)
    },
    
    postBet (token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_bet', JSON.stringify(data), config)
    },
    
    putBet (token, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.put(getAPIURL() + '/bg_bet?fk_user=eq.' + data.fk_user + '&fk_match=eq.' + data.fk_match + '&fk_betgame=eq.' + data.fk_betgame, JSON.stringify(data), config)
    },
    
    getMatchdayWins (competition, betgame) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        
        return apiClient.get(getAPIURL() + '/bg_v_matchday_winner?select=fk_betgame,fk_competition,matchday,fk_user,user_name,points_total,exact,goaldiff,tendency,no_points,betcount&' +
          'fk_competition=eq.' + competition + '&fk_betgame=eq.' + betgame + '&points_total=gt.0', config)
    },
    
    patchUser (token, id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_user?id=eq.' + id, JSON.stringify(data), config)
    },
    
    postRefreshToken (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/refresh_token', data)
    },
    
    patchBetgame (id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_betgame?id=eq.' + id, JSON.stringify(data), config)
    },
    
    getQuestions (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_question?or=(fk_betgame.eq.' + betgameId + ',fk_betgame.is.null)&order=id.asc', config)
    },
    
    getSolutions (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_solution', config)
    },
    
    getAnsers (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_answer?fk_betgame=eq.' + betgameId + '&fk_user=eq.' + userId, config)
    },
    
    postAnswer (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_answer', JSON.stringify(data), config)
    },
    
    deleteUserAnswers (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_answer?fk_user=eq.' + data.fk_user + '&fk_question=eq.' + data.fk_question + '&fk_betgame=eq.' + data.fk_betgame, config)
    },
    
    getOthersBets (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_bet?&fk_betgame=eq.' + betgameId + '&fk_user=neq.' + userId + '&limit=1', config)
    },
    
    getOthersAnswers (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.get(getAPIURL() + '/bg_answer?fk_betgame=eq.' + betgameId + '&fk_user=neq.' + userId + '&limit=1', config)
    },
    
    userEnterBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken() }
        }
        return apiClient.post(getAPIURL() + '/rpc/user_enter_betgame', {
            betgameid: '' + betgameId,
            userid: '' + userId
        }, config)
    },
    
    deleteUserAnswersByBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_answer?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteUserBetsByBetgame (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_bet?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteUserBetgameRelation (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_user_betgame?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    deleteBetgameAuthor (betgameId, userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_author?fk_user=eq.' + userId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    postQuestion (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json',
                'Prefer': 'return=representation' }
        }
        return apiClient.post(getAPIURL() + '/bg_question?select=id', JSON.stringify(data), config)
    },
    
    patchQuestion (id, data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.patch(getAPIURL() + '/bg_question?id=eq.' + id, JSON.stringify(data), config)
    },
    
    deleteQuestion (questionId, betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_question?id=eq.' + questionId + '&fk_betgame=eq.' + betgameId, config)
    },
    
    betCount (betgameId, preferred) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Prefer': 'count=' + preferred}
        }
        return apiClient.head(getAPIURL() + '/bg_bet?fk_betgame=eq.' + betgameId, config)
    },
    
    userCount (betgameId, preferred) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Prefer': 'count=' + preferred}
        }
        return apiClient.head(getAPIURL() + '/bg_user_betgame?fk_betgame=eq.' + betgameId, config)
    },
    
    startBetgameTransfer (betgameId, email) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/start_betgame_transfer', {
            betgame_id: '' + betgameId,
            email_recipient: '' + email
        }, config)
    },
    
    fetchBetgameTransfer(betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_transfer?select=bg_user(email)&fk_betgame=eq.' + betgameId, config)
    },
    
    fetchBetgameTransfersByUser(userId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_betgame_transfer?select=bg_betgame(id,name)&fk_user_recipient=eq.' + userId, config)
    },
    
    deleteBetgameTransfer (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_betgame_transfer?fk_betgame=eq.' + betgameId, config)
    },
    
    finishBetgameTransfer (betgameId) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/rpc/finish_betgame_transfer', {
            betgame_id: '' + betgameId
        }, config)
    },
    
    postUnsubscription (data) {
        var config = {
            headers: { 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_unsubscribed', JSON.stringify(data), config)
    },
    
    postB2BContact (data) {
        var config = {
            headers: { 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_b2bcontact', JSON.stringify(data), config)
    },
    
    deleteSolutions (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.delete(getAPIURL() + '/bg_solution?fk_question=eq.' + data.fk_question, config)
    },
    
    postSolution (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_solution', JSON.stringify(data), config)
    },
    
    fetchCompetitionTeams(competiton) {
        return apiClient.get(getAPIURL() + '/bg_competition_team?select=bg_team(*)&fk_competition=eq.' + competiton)
    },
    
    fetchTournamentRounds(competiton) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_round?fk_competition=eq.' + competiton, config)
    },
    
    fetchTournamentGroups(rounds) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_group?fk_tournament_round=in.(' + rounds + ')', config)
    },
    
    fetchTournamentMatches(competiton) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_match?fk_competition=eq.' + competiton, config)
    },
    
    fetchTournamentGroupTables(groups) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_tournament_group_table?fk_tournament_group=in.(' + groups + ')', config)
    },
    
    fetchBetgameUsersTable (betgame) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.get(getAPIURL() + '/bg_user_betgame?fk_betgame=eq.' + betgame + '&limit=50&select=bg_user(id,nickname),fk_betgame', config)
    },
    
    sendDebug (data) {
        var config = {
            headers: { Authorization: 'Bearer ' + getToken(), 'Content-Type': 'application/json' }
        }
        return apiClient.post(getAPIURL() + '/bg_debug_' + data.name, JSON.stringify(data), config)
    },
}
