/* eslint-disable */
<template>
  <div class="rules" v-bind:style="{ paddingBottom: $store.state.paddingBottom + 'px' }">
  
    <keep-alive>
      <NavigationBar id="rulesNavigationBarId" ref="rulesNavigationBarRef">
        <template v-slot:center>
          {{ $t('menu.rules') }}
        </template>
      </NavigationBar>
    </keep-alive>

    <div v-if="betgame && betgame.competition && betgame.competition.id === 1">
      <div v-if="!this.$store.state.language || this.$store.state.language === 'de'">
        <br/>
        <h1>Das Bundesliga-Tippspiel von SpielerPlus</h1>
        <br/>
        <p>Startet jetzt euer teaminternes Bundesliga-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren Bundesliga-Tippkönig!</p>
        <br/>
        <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Entweder ihr legt euch dafür ein neues SpielerPlus-Team an oder ladet euch die Tippspiel-App TEAMTIP im Store. Hier findet ihr alles, was das Tippspiel-Herz begehrt.</p>
        <br/>
        <b>Tippabgabe</b><br/>
        <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
        <br/>
  
        <b>Punktevergabe</b><br/>
        Richtige Tendenz<br/> 
        <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
        Richtige Tordifferenz<br/>
        <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
          Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
        Richtiges Ergebnis<br/>
        <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
        Falscher Riecher<br/>
        <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
        <br/>
        <br/>
        <div v-if="!isIOSApp">
          <b>Gewinn</b><br/>
          <p >
            Für fünf Teams mit den besten Tippern (meiste Punkte – bei Gleichstand entscheidet das Los) haben wir noch eine besondere Überraschung parat: jeweils ein grandioses SportlerPlus Saison-Nutrition-Paket im Wert von 200€ für eure Truppe.
            Der Rechtsweg ist ausgeschlossen.
          </p>
          <br>
          Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.
        </div>
        <div v-else>
          Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.
        </div>
        <br/>
        <br/>
        <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
      </div>
      <div v-else>
        <br/>
        <h1>The Bundesliga prediction game from SpielerPlus</h1>
        <br/>
        <p>Start your team-internal Bundesliga prediction game now! Your team is already set up as a betting group. Get started and determine who is your Bundesliga tipster!</p>
        <br/>
        <p>Would you like to set up a betting group in a smaller group or with colleagues who are not part of your SpielerPlus team? Either you create a new SpielerPlus team for this or you can download the TEAMTIP prediction game app in the store. Here you will find everything a prediction game heart desires.</p>
        <br/>
        <b>Submission of bets</b><br/>
        <p>You can submit your results tips up to the kick-off of the respective pairing. Your tips will only be visible to all other comrades-in-arms after kick-off. With kick-off you can cheer for the live results and have an eye on what your competitors have typed.</p> 
        <br/>

        <b>Scoring</b><br/>
        Right tendency<br/> 
        <p>The right tendency earns a point. You bet 2: 0, the game ends 1: 0 - one counter dusted off!</p>
        Correct goal difference<br/>
        <p>There are a whopping two points if you are on the right boat and correctly assess the goal difference. Your tip is 2: 0, the match ends 3: 1.
          There are also two counters if you correctly predict a draw. You bet 0: 0, the game ends 1: 1 - two points for you!</p>
        Correct result<br/>
        <p>Strong! You bet 2-0, the end result is 2-0 - three points for you and the day's victory is closer.</p>
        Wrong nose<br/>
        <p>Predicted 2: 0, but the teams split up or it goes 0: 1 - bad luck, no point for you.</p> 
        <br/>
        <br/>
        <div v-if="!isIOSApp">
          <b>Prize</b><br/>
          <p>
            For five teams with the best tipsters (most points - if there is a tie, the lot will decide), we have a special surprise in store: a great SportlerPlus Seasonal Nutrition Package worth € 200 for your team.
            The judges' decision is final.
          </p>
          <br>
          Apple Inc. is in no way involved in the prediction game.
        </div>
        <div v-else>
          Apple Inc. is in no way involved in the prediction game.
        </div>
        <br/>
        <br/>
        <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
      </div>
    </div>
    <div v-if="betgame && betgame.competition && betgame.competition.id === 14">
        <br/>
        <h1>Das Frauen-Bundesliga-Tippspiel von SpielerPlus</h1>
        <br/>
        <p>Startet jetzt euer teaminternes Frauen-Bundesliga-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren Frauen-Bundesliga-Tippkönig!</p>
        <br/>
        <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Entweder ihr legt euch dafür ein neues SpielerPlus-Team an oder ladet euch die Tippspiel-App TEAMTIP im Store. Hier findet ihr alles, was das Tippspiel-Herz begehrt.</p>
        <br/>
        <b>Tippabgabe</b><br/>
        <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
        <br/>

        <b>Punktevergabe</b><br/>
        Richtige Tendenz<br/> 
        <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
        Richtige Tordifferenz<br/>
        <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
          Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
        Richtiges Ergebnis<br/>
        <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
        Falscher Riecher<br/>
        <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
        <br/>
        <br/>
        <div>
          Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.
        </div>
        <br/>
        <br/>
        <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
      </div>
    <div v-if="betgame && betgame.competition && betgame.competition.id === 36">
      <br/>
      <h1>Das EM 2024-Tippspiel von SpielerPlus</h1>
      <br/>
      <p>Startet jetzt euer teaminternes EM 2024-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren EM-Tippkönig!</p>
      <br/>
      <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Legt einfach ein neues SpielerPlus-Team an und ladet alle ein, die mitmachen möchten.</p>
      <br/>
      <b>Tippabgabe</b><br/>
      <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
      <br/>
      <p>In KO-Runden wird das offizielle Endergebnis gewertet, also inklusive aller Tore aus einer möglichen Verlängerung oder einem möglichen Elfmeterschießen. Ein Unentschieden-Tipp wird entsprechend zu 0 Punkten führen.</p>
      <br/>

      <b>Punktevergabe</b><br/>
      Richtige Tendenz<br/> 
      <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
      Richtige Tordifferenz<br/>
      <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
        Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
      Richtiges Ergebnis<br/>
      <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
      Falscher Riecher<br/>
      <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
      <br/>
      <br/>
      <div>
        <p>Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.</p>
      </div>
      <br/>
      <br/>
      <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
    </div>
    <div v-if="betgame && betgame.competition && betgame.competition.id === 107">
      <br/>
      <h1>Das EM 2020-Tippspiel von SpielerPlus</h1>
      <br/>
      <p>Startet jetzt euer teaminternes EM 2020-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren EM-Tippkönig!</p>
      <br/>
      <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Legt einfach ein neues SpielerPlus-Team an und ladet alle ein, die mitmachen möchten.</p>
      <br/>
      <b>Tippabgabe</b><br/>
      <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
      <br/>
      <p>In KO-Runden wird das offizielle Endergebnis gewertet, also inklusive aller Tore aus einer möglichen Verlängerung oder einem möglichen Elfmeterschießen. Ein Unentschieden-Tipp wird entsprechend zu 0 Punkten führen.</p>
      <br/>
      
      <b>Punktevergabe</b><br/>
      Richtige Tendenz<br/> 
      <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
      Richtige Tordifferenz<br/>
      <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
        Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
      Richtiges Ergebnis<br/>
      <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
      Falscher Riecher<br/>
      <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
      <br/>
      <br/>
      <div>
        <p>Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.</p>
      </div>
      <br/>
      <br/>
      <p style="text-align: right; font-size: xx-small; color: darkgray">Version  0.6.12 ({{ spApp }})</p>
    </div>
    <div v-if="betgame && betgame.competition && betgame.competition.id === 139">
      <br/>
      <h1>Das WM 2022-Tippspiel von SpielerPlus</h1>
      <br/>
      <p>Startet jetzt euer teaminternes WM 2022-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren WM-Tippkönig!</p>
      <br/>
      <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Legt einfach ein neues SpielerPlus-Team an und ladet alle ein, die mitmachen möchten.</p>
      <br/>
      <b>Tippabgabe</b><br/>
      <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
      <br/>
      <p>In KO-Runden wird das offizielle Endergebnis gewertet, also inklusive aller Tore aus einer möglichen Verlängerung oder einem möglichen Elfmeterschießen. Ein Unentschieden-Tipp wird entsprechend zu 0 Punkten führen.</p>
      <br/>

      <b>Punktevergabe</b><br/>
      Richtige Tendenz<br/> 
      <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
      Richtige Tordifferenz<br/>
      <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
        Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
      Richtiges Ergebnis<br/>
      <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
      Falscher Riecher<br/>
      <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
      <br/>
      <br/>
      <div>
        <p>Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.</p>
      </div>
      <br/>
      <br/>
      <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
    </div>
    <div v-if="betgame && betgame.competition && betgame.competition.id === 1110">
      <br/>
      <h1>Das WM 2023-Tippspiel von SpielerPlus</h1>
      <br/>
      <p>Startet jetzt euer teaminternes WM 2023-Tippspiel! Euer Team ist bereits als Tipprunde angelegt. Legt los und ermittelt euren WM-Tippkönig!</p>
      <br/>
      <p>Ihr möchtet eine Tipprunde im kleineren Kreis anlegen oder mit Kollegen, die nicht in eurem SpielerPlus-Team Mitglied sind? Legt einfach ein neues SpielerPlus-Team an und ladet alle ein, die mitmachen möchten.</p>
      <br/>
      <b>Tippabgabe</b><br/>
      <p>Ihr könnt eure Ergebnistipps bis zum Anpfiff der jeweiligen Spielpaarung abgeben. Eure Tipps sind für alle anderen Mitstreiter erst nach Anpfiff sichtbar. Mit Anpfiff könnt ihr durch die Live-Ergebnisse mitfiebern und habt im Blick, was eure Konkurrenten getippt haben.</p> 
      <br/>
      <p>In KO-Runden wird das offizielle Endergebnis gewertet, also inklusive aller Tore aus einer möglichen Verlängerung oder einem möglichen Elfmeterschießen. Ein Unentschieden-Tipp wird entsprechend zu 0 Punkten führen.</p>
      <br/>

      <b>Punktevergabe</b><br/>
      Richtige Tendenz<br/> 
      <p>Die richtige Tendenz bringt einen Punkt. Du tippst 2:0, das Spiel endet 1:0 – einen Zähler abgestaubt!</p>
      Richtige Tordifferenz<br/>
      <p>Satte zwei Punkte gibt es, wenn du auf dem richtigen Dampfer bist und auch die Tordifferenz korrekt einschätzt. Dein Tipp ist 2:0, das Match endet 3:1.
        Ebenfalls zwei Zähler gibt es, wenn du ein Unentschieden richtig voraussagst. Du tippst 0:0, das Spiel endet 1:1 – zwei Punkte für dich!</p>
      Richtiges Ergebnis<br/>
      <p>Bockstark! Du tippst 2:0, das Endergebnis lautet 2:0 – drei Zähler für dich und der Tagessieg kommt näher.</p>
      Falscher Riecher<br/>
      <p>2:0 getippt, aber die Mannschaften trennen sich Remis oder es geht 0:1 aus – Pech gehabt, keinen Punkt für dich.</p> 
      <br/>
      <br/>
      <div>
        <p>Apple Inc. ist in keiner Weise an dem Tippspiel beteiligt.</p>
      </div>
      <br/>
      <br/>
      <p style="text-align: right; font-size: xx-small; color: darkgray">Version 0.6.12 ({{ spApp }})</p>
    </div>
  </div>
</template>

<script>
  
  import NavigationBar from '../components/NavigationBar'
  export default {
    name: 'Rules',
    components: { NavigationBar },
    methods: {
      openMenu () {
        this.$modal.show('betgame-menu')
      }
    },
    computed: {
      isIOSApp() {
        return navigator.userAgent.indexOf('spielerplus-apple') > 0
      },
      betgame() {
        return this.$store.state.betgame
      },
      spApp () {
        return this.$store.state.spApp
      },
      pointsBetResult () {
        return this.$store.state.betgame.points_bet_result
      },
      pointsBetGoaldiff () {
        return this.$store.state.betgame.points_bet_goaldiff
      },
      pointsBetTendency () {
        return this.$store.state.betgame.points_bet_tendency
      },
      betGameRules () {
        return this.$store.state.betgame.rules
      },
    }
  }
</script>
<style lang="scss" scoped>
  .rules_header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
</style>
